import React, { useContext, useState } from "react";
import { ContentContext } from "../../../utils/providers/content";

/**
 * Styles
 */
import "./feedback.scss";

/**
 * UI Components
 */
import CommentWindow from "../comment-window/comment-window";

/**
 * Functional component that sits above the content and provided functionality for clicking &
 * clicking/dragging to leave pinned comments and drawn-on lines and shapes.
 *
 * The data captured from the user actions is streamed back to the content component to appear as
 * a comment box for the user to enter their comment before saving it down as feedback.
 *
 * @returns HTML markup and functionality for the feedback tile that sits above the content
 */
function Feedback(props) {
    const [showCommentWindow, setShowCommentWindow] = useState(false);

    /**
     * Get the ID for the content from the upper context
     */
    const { feedbackTool, setCursorX, setCursorY } = useContext(ContentContext);

    /**
     * When the feedback tile is clicked
     *
     * @param {object} e Event trigger from the feedback tile being clicked
     */
    const feedbackClick = (e) => {
        /**
         * Update the state with the current cursor position
         */
        setCursorPosition(e);
        /**
         * Toggle the comment window to show
         */
        setShowCommentWindow(true);
        /**
         * Set the playing attribute on the context to false to pause any videos
         */
        if (props.pause) {
            props.pause();
        }
    };

    /**
     * Collects and sets the position of the cursor when called
     *
     * @param {object} e Event from the click event on the feedback tile
     * @returns Nothing. Instead sets the state with the cursor position relative to the feedback
     * tile that was clicked
     */
    const setCursorPosition = (e) => {
        /**
         * Get the height and width of the feedback tile component
         */
        const height = document.getElementById("content-media-holder").scrollHeight;
        const width = document.getElementById("content-media-holder").clientWidth;
        /**
         * Get the mouse position relative to the feedback tile
         */
        const container = e.target.getBoundingClientRect();
        const xPos = e.clientX - container.left;
        const yPos = e.clientY - container.top;
        /**
         * Work out the position as a percentage relative to the feedback tile
         */
        const xPosPercent = (parseFloat(xPos) / parseFloat(width)) * 100;
        const yPosPercent = (parseFloat(yPos) / parseFloat(height)) * 100;
        /**
         * Set the state with the cursor percentages
         */
        setCursorX(xPosPercent);
        setCursorY(yPosPercent);
    };

    return (
        <div className={["content-feedback-tile", (feedbackTool === "PIN") && "is-active"].join(" ")}>
            <div className="content-feedback-click-zone" onClick={feedbackClick} />
            <CommentWindow show={showCommentWindow} cancel={() => setShowCommentWindow(false)} />
        </div>
    );
}

export default Feedback;
