import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase";
import { AuthContext } from "../../../utils/providers/auth";
import { AlertsContext } from "../../../utils/providers/alerts";
import { ModalContext } from "../../../utils/providers/modal";
import { arrayUnion, auth, db } from "../../../utils/firebase";
import { TrashIcon, ImageIcon, VideoIcon, LinkIcon, CopyIcon, DownloadIcon } from "../../../utils/svgs";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import JSZip from "jszip";
import saveAs from "file-saver";
import moment from "moment";
import "./content.scss";

/**
 * UI components
 */
import Button from "../../design-system/ui/button/button";

/**
 * Functional component to setup a listener on the content document in the databse for streamiong changes 
 * back down to the state. This content card is used in the timeline display.
 * 
 * @param {object} props Props object should contain clientID, projectID and contentID
 * @returns HTML markup for the content card shown in the timeline display
 */
function Content(props) {
    const [contentTitle, setContentTitle] = useState("");
    const [contentUploaded, setContentUploaded] = useState("");
    const [contentType, setContentType] = useState({});
    const [contentUploadedBy, setContentUploadedBy] = useState({});
    const [contentSeenBy, setContentSeenBy] = useState([]);
    const [commentsCount, setCommentsCount] = useState(0);
    const [seenBy, setSeenBy] = useState("");
    const [files, setFiles] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [multipleDownloading, setMultipleDownloading] = useState(false);
    const [viewingFiles, setViewingFiles] = useState(false);

    /**
     * Deconstruct the user object from the context
     */
    const { user } = useContext(AuthContext);

    /**
     * Get the push alert function from the alerts context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Get the modal functions from it's context
     */
    const { showModal } = useContext(ModalContext);

    /**
     * Deconstuct the IDs we need from the state
     */
    const { clientID, projectID, contentID, permissions, canDownload } = props;

    /**
     * When all 3 IDs have been loaded in
     */
    useEffect(() => {
        /**
         * Setup a listener on the database for this content card
         */
        const unsubscribe = db.doc(`clients/${clientID}/projects/${projectID}/content/${contentID}`)
            .onSnapshot((contentSnap) => {
                /**
                 * Make sure this content exists
                 */
                if (contentSnap.exists) {
                    /**
                     * Deconstruct the data we need from the snapshot
                     */
                    const { title, type, created, comments, uploaded_by, seen_by } = contentSnap.data();
                    /**
                     * Generate a timestamp for the created datetime
                     */
                    const createdFormatted = created ? moment.unix(created.seconds).format("DD/MM/YYYY HH:mma") : "No Date Available";
                    /**
                     * Update the state
                     */
                    setContentTitle(title);
                    setContentType(type);
                    setContentUploaded(createdFormatted);
                    setCommentsCount(comments || 0);
                    setContentUploadedBy({ id: uploaded_by });
                    setContentSeenBy(seen_by || []);
                }
            });
        /**
         * Remove the listener when the component unmounts
         */
        return () => unsubscribe();
    }, [clientID, projectID, contentID]);

    /**
     * When all 3 IDs have been loaded in
     */
    useEffect(() => {
        /**
         * Setup the listener
         */
        const unsubscribe = db.collection(`clients/${clientID}/projects/${projectID}/content/${contentID}/files`)
            .onSnapshot((filesSnap) => {
                /**
                 * Loop over the file documents in this snapshot
                 */
                filesSnap.docChanges().forEach((change) => {
                    /**
                     * File document added
                     */
                    if (change.type === "added") {
                        setFiles((files) => [
                            ...files,
                            { id: change.doc.id, ...change.doc.data() }
                        ]);
                    }
                    /**
                     * File document updated
                     */
                    if (change.type === "modified") {
                        setFiles((files) => {
                            let updatedFiles = [...files];
                            for (let i in files) {
                                if (files[i].id === change.doc.id) {
                                    updatedFiles[i] = { id: change.doc.id, ...change.doc.data() };
                                    break;
                                }
                            }
                            return updatedFiles;
                        });
                    }
                    /**
                     * File document removed
                     */
                    if (change.type === "removed") {
                        setFiles((files) => files.filter((files) => files.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unmount
         */
        return () => unsubscribe();
    }, [clientID, projectID, contentID]);

    /**
     * when the ID of the user that uploaded the content is recognised
     */
    useEffect(() => {
        /**
         * Get the user document from the database
         */
        db.doc(`users/${contentUploadedBy.id}`).get().then((userDoc) => {
            /**
             * Make sure this user exists
             */
            if (userDoc.exists) {
                /**
                 * Deconstruct the name from the user document
                 */
                const { name, email } = userDoc.data();
                /**
                 * Update the state
                 */
                setContentUploadedBy({ ...contentUploadedBy, name: name || email });
            }
        });
    }, [contentUploadedBy?.id]);

    /**
     * When the IDs property on the seenBy object is updated
     */
    useEffect(() => {
        /**
         * Setup an array for storing the details
         */
        let seenByString = [];
        /**
         * Has it been seen by anyone
         */
        if (contentSeenBy?.length > 0) {
            /**
             * Loop over each
             */
            contentSeenBy.forEach((user) => {
                /**
                 * Get their details form the database
                 */
                db.doc(`users/${user}`).get().then((userDoc) => {
                    /**
                     * Deconstruct the name and email from their document
                     */
                    const { name, email } = userDoc.data();
                    /**
                     * Push them on the array
                     */
                    seenByString.push(name || email);
                    /**
                     * Then update the state
                     */
                    setSeenBy(seenByString.join(", "))
                });
            });
        }
    }, [contentSeenBy]);

    /**
     * Download the files under the content
     */
    const downloadContent = (id) => {
        /**
         * If there was an ID passed in and there is more than 1 file
         */
        if (files.length > 1 && id) {
            /**
             * Multiple files, single download ID
             */
            setDownloading(id);
        } else if (files.length > 1 && !id) {
            /**
             * If there was no ID passed in but are more than 1 file
             */
            setDownloading("all");
        } else {
            /**
             * Otherwise if there is 1 file and no ID passed in
             */
            setDownloading(true);
        }

        /**
         * Gets the file as a buffer from the given URL
         */
        const fetchFileURL = (url) => {
            /**
             * Return this as a promise
             */
            return new Promise(function (resolve, reject) {
                /**
                 * Setup a new get request to that url
                 */
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url, true);
                /**
                 * We want the result as a buffer
                 */
                xhr.responseType = "arraybuffer";
                xhr.onreadystatechange = function (evt) {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            /**
                             * If successful return the buffer
                             */
                            resolve(xhr.response);
                        } else {
                            /**
                             * Otherwise return an error
                             */
                            reject(new Error(`Error getting ${url}. Status code:${xhr.status}`));
                        }
                    }
                };
                /**
                 * Send the request
                 */
                xhr.send();
            });
        };
        /**
         * Setup the JSZip libary
         */
        const zip = new JSZip();
        /**
         * Loop over each of the files
         */
        for (let i = 0; i < files.length; i++) {
            /**
             * Are we only downloading a specific file?
             */
            if (!id || (id && files[i].id === id)) {
                /**
                 * Get the name, extension and original quality download URL
                 */
                let fileFormat = files[i].format.split("/");
                let fileName = files[i].name;
                let fileURL = files[i].sizes.original;
                /**
                 * Get the current timestamp and build an update string
                 */
                const updateQuery = {}
                updateQuery[`downloadedBy.${auth.currentUser.uid}`] = firebase.firestore.FieldValue.serverTimestamp();
                /**
                 * Push the current users ID onto the downloadedBy array for this file
                 */
                db.doc(`clients/${clientID}/projects/${projectID}/content/${contentID}/files/${files[i].id}`).update(updateQuery);
                /**
                 * Perform the zip operation
                 */
                zip.file(`${fileName}.${fileFormat[1]}`, fetchFileURL(fileURL));
            }
            /**
             * If we are on the last file in the group
             */
            if (i === files.length - 1) {
                /**
                 * Update the state with the relevant ID for the download or ALL tag for multiple
                 */
                if (files.length > 1 && id) {
                    /**
                     * Multiple files, single download ID
                     */
                    setDownloading(id);
                } else if (files.length > 1 && !id) {
                    /**
                     * If there was no ID passed in but are more than 1 file
                     */
                    setDownloading("all");
                } else {
                    /**
                     * Otherwise if there is 1 file and no ID passed in
                     */
                    setDownloading(true);
                }
                /**
                 * Generate the zipped object
                 */
                zip.generateAsync({ type: "blob" }).then((content) => {
                    /**
                     * Save the zip folder to the users device
                     */
                    saveAs(content, `${contentTitle}.zip`);
                    /**
                     * Update the state back to normal
                     */
                    setDownloading(false);
                    /**
                     * Show an alert to say it was successful
                     */
                    pushAlert({ type: "SUCCESS", title: "Downloading..." });
                });
            }
        }
    };

    /**
     * Copy a link to the users clipboard
     */
    const copyToClipboard = (id) => {
        /**
         * Build the string to copy to clipboard
         */
        const clipboardLink = "https://www.app.feeval.com/content/" + (id ? id : files[0].id);
        /**
         * Internet Explorer fallback check
         */
        if (window.clipboardData && window.clipboardData.setData) {
            /**
             * Prevent textarea being shown while dialog is visible.
             */
            return window.clipboardData.setData("Text", clipboardLink);
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            /**
             * Create a new textarea, fill the content and style it to the back
             */
            let textarea = document.createElement("textarea");
            textarea.textContent = clipboardLink;
            textarea.style.position = "fixed";
            /**
             * Append it to the DOM and then select it
             */
            document.body.appendChild(textarea);
            textarea.select();
            /**
             * Attempt to copy the string to the clipboard
             */
            try {
                document.execCommand("copy");
                /**
                 * Show an alert to say it was successful
                 */
                return pushAlert({ type: "SUCCESS", title: "Link copied to clipboard" });
            }
            /**
             * Catch any exceptions
             */
            catch (e) {
                /**
                 * Show an alert to say it was unsuccessful
                 */
                return pushAlert({ type: "ERROR", title: "Couldn't copy to clipboard" });
            }
            /**
             * Cleanup
             */
            finally {
                document.body.removeChild(textarea);
            }
        }
    }

    /**
     * Check to see if the content removal is wanted
     */
    const checkRemoval = () => {
        /**
         * Show the modal
         */
        showModal({
            type: "ALERT",
            title: "Are you sure?",
            body: "When you remove content from FEEVAL all associated feedback will be removed.",
            cancel: {
                label: "Cancel",
                action: () => { return false; },
            },
            next: {
                label: "Yes, Please",
                action: () => removeContent(),
            }
        });
    }

    /**
     * Remove the content from the project
     */
    const removeContent = () => {
        /**
         * Remove the content document from the database.
         * 
         * A cloud function will be fired upon deletion to handle the cleanup job involving removing 
         * files and associated comments etc.
         */
        db.doc(`clients/${clientID}/projects/${projectID}/content/${contentID}`).delete();
        /**
         * Push an alert to say it's done
         */
        pushAlert({ type: "SUCCESS", title: "Content removed" })
    }

    return (
        <div className="timeline-content-card">
            {/* Card header */}
            <div className="content-head">
                <div className="action-type-wrap">
                    {/* What icon needs printing to represent the content type? */}
                    <>
                        {/* Image format */}
                        {contentType?.format === "image" && <ImageIcon />}
                        {/* Video format */}
                        {contentType?.format === "video" && <VideoIcon />}
                        {/* Website URL format */}
                        {contentType?.format === "url" && <LinkIcon />}
                    </>
                </div>

                {/* Content type, title and upload date */}
                <h6>
                    {contentType?.name || "Unknown"}
                    {files.length > 1 && "s"}
                </h6>
                <h1>{contentTitle}</h1>
                <h2>{contentUploaded}</h2>

                {/* Can the current user remove content from this project? */}
                {(user?.is_agency && permissions?.COLLABORATE) &&
                    <div className="remove-content" onClick={() => checkRemoval()}>
                        <TrashIcon />
                    </div>
                }
            </div>

            {/* Card body */}
            <div className="content-image">
                <div className={["c-image-wrap", files.length > 1 && "multiple"].join(" ")}>
                    {/* Print small thumbnails of the content into the preview card */}
                    {files.map((file) => (
                        <div key={file.id} className="c-image-inner">
                            {/* PDF documents */}
                            {file.format.startsWith("application") &&
                                <Document file={file.sizes.original}>
                                    <Page pageNumber={1} />
                                </Document>
                            }

                            {/* Image formats */}
                            {file.format.startsWith("image") &&
                                <img key={file.id} src={file.sizes._256 || file.sizes.original} alt="Content Item" />
                            }

                            {/* Video formats */}
                            {file.format.startsWith("video") &&
                                <p className="thumbnail-pending">Thumbnail pending</p>
                            }
                        </div>
                    ))}

                    {/* If there is more than 1 file */}
                    {(files.length > 1) &&
                        <div className="c-file-count">
                            <p>{files.length}</p>
                            <small>files</small>
                        </div>
                    }
                </div>

                {/* Content meta such as uploader, seen by, comments etc */}
                <div className="content-body">
                    <div className="content-stat">
                        <h4>Uploaded by</h4>
                        <h5>{contentUploadedBy?.name || "Unknown"}</h5>
                    </div>
                    <div className="content-stat">
                        <h4>Seen by</h4>
                        <h5>{seenBy ? seenBy : "Nobody yet"}</h5>
                    </div>
                    <div className="content-stat">
                        <h4>Comments</h4>
                        <h5>{commentsCount}</h5>
                    </div>

                    {/* Is there only 1 file for this content block */}
                    {(files.length === 1) &&
                        <div className="content-stat">
                            <h4>Direct Link</h4>
                            <h5><span onClick={() => copyToClipboard()}>Copy to clipboard</span></h5>
                        </div>
                    }
                </div>
            </div>

            {/* Card footer */}
            <div className="content-footer">
                {/* Is there a single file meaning we can navigate the user straight away? */}
                {files.length === 1 &&
                    <Button
                        label="View &amp; Manage Feedback"
                        onClick={() => window.location.href = `/content/${files[0].id}`} />
                }

                {/* Are there more than 1 file, meaning we need to show a dropdown list when clicked? */}
                {files.length > 1 &&
                    <Button
                        label={viewingFiles ? "Hide Files" : "View Files"}
                        onClick={() => setViewingFiles((viewingFiles) => !viewingFiles)} />
                }

                {/* Show the download button if allowed */}
                {(canDownload && files.length === 1) &&
                    <Button
                        label="Download"
                        loading={downloading}
                        loadingText="Downloading..."
                        onClick={() => downloadContent()} />
                }

                {(canDownload && files.length > 1) &&
                    <Button
                        label="Download All"
                        loading={downloading === "all"}
                        loadingText="Downloading..."
                        onClick={() => downloadContent()} />
                }
            </div>

            {/* Content list to display if more than 1 file */}
            {(files.length > 1) &&
                <div className={["content-footer-list", viewingFiles ? "is-open" : ""].join(" ")}>
                    {/* Loop over each file to display into it's own row */}
                    {files.map((file) => (
                        <div key={file.id} className="content-footer-list-file">
                            {/* Content image */}
                            <div className="cfl-image">
                                <div className="cfl-image-wrap">
                                    {/* Is this a pdf format? */}
                                    {(file.format === "application/pdf") &&
                                        <Document file={file.sizes.original}>
                                            <Page pageNumber={1} />
                                        </Document>
                                    }

                                    {/* If it's not, it's either going to be an image or video */}
                                    {(file.format !== "application/pdf") &&
                                        <img src={file.sizes._64 || file.sizes._256 || file.sizes.original} alt={file.id} />
                                    }
                                </div>
                            </div>

                            {/* File name and comment count */}
                            <div className="cfl-text">
                                <p>{file.name}</p>
                                <small>0 comments</small>
                            </div>

                            {/* Button actions (download, view and copy link) */}
                            <div className="cfl-actions">
                                {/* Copy the link to clipboard */}
                                <Button
                                    small={true}
                                    icon={<CopyIcon />}
                                    onClick={() => copyToClipboard(file.id)} />

                                {/* Navigate to the content page */}
                                <Button
                                    small={true}
                                    icon={<LinkIcon />}
                                    onClick={() => window.location.href = `/content/${file.id}`} />

                                {/* Can the content be downloaded */}
                                {canDownload &&
                                    <Button
                                        small={true}
                                        icon={<DownloadIcon />}
                                        loading={downloading === file.id}
                                        onClick={() => downloadContent(file.id)} />
                                }
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}

export default Content;