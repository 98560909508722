import React, { useContext, useEffect, useState } from "react";
import { Switch, Route, NavLink } from 'react-router-dom';
import { db, auth } from "../../utils/firebase";
import { AuthContext } from "../../utils/providers/auth";
import { ChevronRightIcon } from "../../utils/svgs";
import "./client.scss";

/**
 * UI components & structure blocks
 */
import Tile from "../../components/design-system/structure/tile/tile";
import Title from "../../components/design-system/structure/title/title";
import Navigation from "../../components/design-system/structure/navigation/navigation";

/**
 * Route components
 */
import Projects from "../../components/client/projects/projects";
import Users from "../../components/client/users/users";
import Edit from "../../components/client/edit/edit";
import Error404 from "../errors/404";

/**
 * 
 * 
 * @param {object} props
 * @returns
 */
function Client(props) {
    const [clientName, setClientName] = useState("");
    const [clientAgency, setClientAgency] = useState("");
    const [userPermissions, setUserPermissions] = useState({});

    /**
     * Deconstruct the current user data from the context
     */
    const { user } = useContext(AuthContext);

    /**
     * Get the client id from the URL and the URL path
     */
    const { id } = props.match.params;
    const { url } = props.match;

    /**
     * With a client ID present
     */
    useEffect(() => {
        /**
         * Setup a listener for the client details
         */
        const unsubscribe = db.doc(`clients/${id}`).onSnapshot((clientDoc) => {
            /**
             * Make sure the client document exists
             */
            if (clientDoc.exists) {
                /**
                 * Deconstruct the data we need from the client document
                 */
                const { name, agency } = clientDoc.data();
                /**
                 * Update the state
                 */
                setClientName(name);
                setClientAgency(agency);
            }
        });
        /**
         * Remove the listener when the component unloads
         */
        return () => unsubscribe();
    }, [id]);

    /**
     * When the client ID is present
     */
    useEffect(() => {
        /**
         * Setup a listener on the current users client document to stream permissions back
         */
        const unsubscribe = db.doc(`users/${auth.currentUser.uid}/clients/${id}`)
            .onSnapshot((userPermissionsDoc) => {
                /**
                 * Make sure the document exists first
                 */
                if (userPermissionsDoc.exists) {
                    /**
                     * Set the permissions to the local state
                     */
                    setUserPermissions(userPermissionsDoc.data().permissions);
                }
            });
        /**
         * Remove the listener when the component is unloaded
         */
        return () => unsubscribe();
    }, [id]);

    return (
        <Tile fullPage={true}>
            <Title>
                <h1>
                    {/* Is this an agency user? */}
                    {user.is_agency && <NavLink to="/clients">Clients</NavLink>}

                    <ChevronRightIcon />

                    {/* Client name */}
                    {clientName}
                </h1>
            </Title>

            {/* Client navigation routes */}
            <Navigation>
                {/* Projects link */}
                <NavLink activeClassName="is-active" to={`${url}/projects`}>
                    Projects
                </NavLink>

                {/* Is the current user of agency type and has administrate permissions? */}
                {(user.is_agency && userPermissions.ADMINISTRATE) &&
                    <>
                        {/* Users link */}
                        <NavLink activeClassName="is-active" to={`${url}/users`}>
                            Users
                        </NavLink>

                        {/* Edit details link */}
                        <NavLink activeClassName="is-active" to={`${url}/edit`}>
                            Edit Details
                        </NavLink>
                    </>
                }
            </Navigation>

            {/* Routes for the various pages relating to a client account */}
            <Switch>
                {/* Projects route */}
                <Route path="/client/:id/projects" exact component={() =>
                    <Projects clientID={id} />} />

                {/* Is the current user of agency type and has administrate permissions? */}
                {(user.is_agency && userPermissions.ADMINISTRATE) &&
                    <>
                        {/* Users route */}
                        <Route path="/client/:id/users" exact component={() =>
                            <Users clientID={id} agencyID={clientAgency} />} />

                        {/* Edit details route */}
                        <Route path="/client/:id/edit" exact component={() =>
                            <Edit clientID={id} />} />
                    </>
                }

                {/* Any other page that doesn't have a route */}
                <Route path="*" component={Error404} />
            </Switch>
        </Tile >
    );
}

export default Client;