import React, { useState, useContext, useRef, useEffect } from "react";
import firebase from "firebase";
import { db, auth, storage, arrayUnion } from "../../../utils/firebase";
import { checkForPremium } from "../../../utils/utils";
import { FileDrop } from "react-file-drop";
import { AlertsContext } from "../../../utils/providers/alerts";
import { screen_sizes } from "../../../utils/selects/website-capture-sizes";
import { TimesIcon, TrashIcon } from "../../../utils/svgs";
import "./upload.scss";

/**
 * UI components
 */
import Input from "../../design-system/ui/inputs/input";
import Select from "../../design-system/ui/select/select";
import Button from "../../design-system/ui/button/button";
import MultipleSelect from "../../design-system/ui/select/multiple-select";

/**
 * Required to generate the ID for the new content document
 */
const random = require("randomstring");

/**
 * Functional component to handle the uploading of files to a content object
 */
function Upload(props) {
    const [contentTitle, setContentTitle] = useState("");
    const [uploadType, setUploadType] = useState("");
    const [uploadFiles, setUploadFiles] = useState([]);
    const [websiteURL, setWebsiteURL] = useState("");
    const [websiteCaptureSizes, setWebsiteCaptureSizes] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [websiteURLError, setWebsiteURLError] = useState("");
    const [isPremium, setIsPremium] = useState(false);

    /**
     * Get the pushAlert mehtod from the alerts context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Deconstruct the clientID and projectID from the props
     */
    const { clientID, projectID, firstUpload } = props;

    /**
     * Setup a ref for the click event on the file drop area
     */
    const inputRef = useRef(null);

    /**
     * Check to see if the user has a premium license for the given agency
     */
    useEffect(() => {
        /**
         * Pull the client document from the database
         */
        db.doc(`clients/${clientID}`)
            .get().then(async (clientDoc) => {
                /**
                 * Deconstruct the agencyID from the client document
                 */
                const { agency } = clientDoc.data();
                /**
                 * Check to see if the user is premium under the agency
                 */
                const premiumCheck = await checkForPremium(auth.currentUser.uid, agency);
                /**
                 * Push the result into the local state
                 */
                setIsPremium(premiumCheck);
            });
    }, []);

    /**
     * Handle the drop of files for image formats
     *
     * @param {Object} files Files dropped into the area
     * @param {Object} e Event object for the drop
     */
    const handleFilesDropped = (files, event) => {
        /**
         * Check for an empty array
         */
        if (files.length > 0) {
            /**
             * Loop over the files
             */
            for (const [index, file] of Object.entries(files)) {
                /**
                 * Don't push this index becasue it'll only be holding the file count
                 */
                if (index !== "length") {
                    /**
                     * Generate a new ID for using on the document references for this file
                     */
                    const uploadID = random.generate(20);
                    /**
                     * Strip everything from the name to jsut keep letters
                     */
                    let cleanName = file.name.match(/[a-zA-Z]+/g);
                    let fileID = `${cleanName}${file.size}`;
                    let fileUploadTime = file.lastModified;
                    /**
                     * Add an ID and an errors object to the file
                     */
                    let newFile = {
                        id: fileID,
                        uploadID,
                        error: {},
                        file,
                        upload: {},
                    };
                    /**
                     * Check if this file already exists
                     */
                    uploadFiles.forEach((file) => {
                        if (file.id === fileID) {
                            newFile.id = `${fileID}${fileUploadTime}`;
                            newFile.error = {
                                message: "You've already added this file to the upload.",
                            };
                        }
                    });
                    /**
                     * Check for a valid file format
                     */
                    const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf", "video/mp4", "video/quicktime"];
                    if (!allowedFormats.includes(file.type)) {
                        newFile.error = {
                            message: "This file format is not supported.",
                        };
                    }
                    /**
                     * Push the files object to the array for sending to state
                     */
                    setUploadFiles((uploadFiles) => [...uploadFiles, newFile]);
                }
            }
        }
    }

    /**
     * On click handler for when a user clicks on the media input instead of drag and drop
     * 
     * @param {object} event Event object from the input change event, should contain the "files"
     */
    const handleFileClicked = (event) => {
        /**
         * Get the files from the click event
         */
        const { files } = event.target;
        /**
         * Run the files through the format checks and push them to the state
         */
        handleFilesDropped(files);
    }

    /**
     * Check the click to see if it's target was to remove content or open the media chooser
     */
    const checkForValidClick = (e) => {
        /**
         * Make sure the click event wasn't on a trash icon
         */
        if (e.target.id !== "remove-file") {
            inputRef.current.click();
        }
    }

    /**
     * Removes the passed file ID from the upload list
     *
     * @param {String} fileID ID of the file to remove
     */
    const removeFileFromList = (fileID) => {
        /**
         * Filter out the passed ID from the list
         */
        setUploadFiles((files) => files.filter(
            (file) => file.id !== fileID
        ));
    };

    /**
     * Upload the content currently held in the state array to the storage bucket
     */
    const uploadContent = async () => {
        /**
         * Update the state to uploading 
         */
        setUploading(true);
        /**
         * Generate a new ID for this content document
         */
        const contentID = random.generate(20);
        /**
         * Init a variable to store the upload result
         */
        let uploadResult = true;
        /**
         * Run the relevant upload path for the file type
         */
        switch (uploadType.option) {
            /**
             * Image formats
             */
            case "image":
                uploadResult = await uploadPathImage(contentID);
                break;
            /**
             * Video formats
             */
            case "video":
                uploadResult = await uploadPathVideo(contentID);
                break;
            /**
             * URL formats
             */
            case "website_url":
                uploadResult = await uploadPathWebsiteURL(contentID);
                break;
            /**
             * Default return
             */
            default:
                return false;
        }
        /**
         * If the upload was successful
         */
        if (!uploadResult.includes(false)) {
            /**
             * Once the uploads have taken place
             */
            await db.doc(`clients/${clientID}/projects/${projectID}/content/${contentID}`).set({
                title: contentTitle,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                updated: firebase.firestore.FieldValue.serverTimestamp(),
                uploaded_by: auth.currentUser.uid,
                users: arrayUnion(auth.currentUser.uid),
                type: { name: uploadType.value, format: uploadType.option },
            }, { merge: true });
            /**
             * Update the last updated timestamp on the project
             */
            await db.doc(`clients/${clientID}/projects/${projectID}`).update({
                updated: firebase.firestore.FieldValue.serverTimestamp(),
            });
            /**
             * Empty the state
             */
            setUploading(false);
            setContentTitle("");
            setWebsiteURL("");
            setWebsiteCaptureSizes([]);
            setUploadType([]);
            setUploadFiles([]);
            /**
             * Push an alert to say the upload was successful
             */
            pushAlert({ type: "SUCCESS", title: "Content uploaded" });
        } else {
            /**
             * Empty the state
             */
            setUploading(false);
        }
    }

    /**
     * Upload process for image files
     *
     * @function
     */
    const uploadPathImage = async (contentID) => {
        /**
         * Loop over each file
         */
        const results = uploadFiles.map(async (file, index) => {
            /**
             * If there are no errors on the file
             */
            if (!file.error.message) {
                /**
                 * Upload the file
                 */
                const downloadURL = await handleFileUpload(file, contentID);
                /**
                 * Get the updated file info from state
                 */
                const fileInfo = uploadFiles[index];
                /**
                 * Create a content view record
                 */
                await db.doc(`content/${fileInfo.uploadID}`).set({
                    activeUsers: [],
                    comments: [],
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    client: clientID,
                    content: contentID,
                    project: projectID,
                }, { merge: true });
                /**
                 * Save the file info into the database
                 */
                await db.doc(`clients/${clientID}/projects/${projectID}/content/${contentID}/files/${fileInfo.uploadID}`).set({
                    name: fileInfo.file.name,
                    size: fileInfo.file.size,
                    modified: fileInfo.file.lastModified,
                    format: fileInfo.file.type,
                    uploaded: firebase.firestore.FieldValue.serverTimestamp(),
                    sizes: {
                        _64: null,
                        _256: null,
                        _512: null,
                        original: downloadURL,
                    },
                }, { merge: true });
            }
        });
        /**
         * Wait for all the files to finish uploading
         */
        return await Promise.all(results);
    };

    /**
     * Upload process for video files
     */
    const uploadPathVideo = async (contentID) => {
        /**
         * Loop over each file
         */
        const results = uploadFiles.map(async (file, index) => {
            /**
             * If there are no errors on the file
             */
            if (!file.error.message) {
                /**
                 * Calculate the size of the file in MB
                 */
                const sizeInMB = file.file.size / 1024 / 1024;
                /**
                 * Is this video less than 20MB?
                 */
                if (sizeInMB <= 50) {
                    /**
                     * Upload the file
                     */
                    const downloadURL = await handleFileUpload(file, contentID);
                    /**
                     * Get the updated file info from state
                     */
                    const fileInfo = uploadFiles[index];
                    /**
                     * Create a content view record
                     */
                    await db.doc(`content/${fileInfo.uploadID}`).set({
                        activeUsers: [],
                        comments: [],
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        client: clientID,
                        content: contentID,
                        project: projectID,
                    }, { merge: true });
                    /**
                     * Save the file info into the database
                     */
                    await db.doc(`clients/${clientID}/projects/${projectID}/content/${contentID}/files/${fileInfo.uploadID}`).set({
                        name: fileInfo.file.name,
                        size: fileInfo.file.size,
                        modified: fileInfo.file.lastModified,
                        format: fileInfo.file.type,
                        uploaded: firebase.firestore.FieldValue.serverTimestamp(),
                        sizes: {
                            _64: null,
                            _256: null,
                            _512: null,
                            original: null,
                        },
                        video: {
                            original: downloadURL,
                        }
                    }, { merge: true });
                } else {
                    /**
                     * Update the state with the file whose upload is complete
                     */
                    setUploadFiles((files) => {
                        let updatedFiles = [...files];
                        for (let i in files) {
                            if (files[i].id === file.id) {
                                updatedFiles[i] = {
                                    ...files[i],
                                    error: {
                                        message: "File size too big"
                                    }
                                };
                                break;
                            }
                        }
                        return updatedFiles;
                    });
                    /**
                     * Show an alert to say the file size is too big
                     */
                    pushAlert({
                        type: "ERROR",
                        title: "File size to big",
                        body: "There is a 50MB limit on video files, please upload content within this size."
                    });
                    /**
                     * Return a false statement to stop the upload
                     */
                    return false;
                }
            }
        });
        /**
         * Wait for all the files to finish uploading
         */
        return await Promise.all(results);
    };

    /**
     * The upload process for a website screenshot works differently from the others because of the time it 
     * currently takes to generate these screenshots. Instead of uploading here on the client side wel'll 
     * just add a new content document (like usual) with a flag to fire a cloud funciton when it's recognised.
     * 
     * The cloud function will take the heavy lifting from there and generate the screenshots before assigning
     * them to the content document which is when the front-end will stream the update into the DOM.
     */
    const uploadPathWebsiteURL = async (contentID) => {
        if (!websiteURL.startsWith("https://www.")) {
            /**
             * Set an error into the state
             */
            setWebsiteURLError("Please ensure your URL starts: https://www.");
            /**
             * Return a falsey statement to stop the upload process
             */
            return [false];
        } else {
            /**
             * Once the uploads have taken place
             */
            await db.doc(`clients/${clientID}/projects/${projectID}/content/${contentID}`).set({
                websiteURL: {
                    url: websiteURL,
                    sizes: websiteCaptureSizes,
                }
            }, { merge: true });
            /**
             * Return a a truthy statement to continue the adding process
             */
            return [true];
        }
    }

    /**
     * Handles the uploading of the file into the sotrage bucket
     *
     * @param {file} file File object to upload to storage
     * @param {string} contentID ID of the content object to attach the file upload to
     */
    const handleFileUpload = (file, contentID) => {
        /**
         * Build out some metadata for the image
         */
        const meta = {
            customMetadata: {
                clientID,
                projectID,
                contentID,
                fileID: file.uploadID,
                fileName: file.file.name,
                uploadedBy: auth.currentUser.uid,
                awaitingTranscode: true,
                type: "content",
            },
        };
        /**
         * Move the file into firebase storage
         */
        const upload = storage.ref(`content/${clientID}/${projectID}/${contentID}/${file.uploadID}/${file.uploadID}`).put(file.file, meta);
        /**
         * Return a promise listening to the upload progress
         */
        return new Promise((resolve, reject) => {
            /**
             * Setup the monitor for the file
             */
            monitorUploadProgress(upload, file.id).then(() => {
                /**
                 * When it's complete, get the downloadURL from the callback
                 */
                upload.snapshot.ref.getDownloadURL().then((url) => {
                    /**
                     * Update the state with the file whose upload is complete
                     */
                    setUploadFiles((files) => {
                        let updatedFiles = [...files];
                        for (let i in files) {
                            if (files[i].id === file.id) {
                                updatedFiles[i] = {
                                    ...files[i],
                                    upload: {
                                        done: true,
                                        download_url: url
                                    },
                                    downloadURL: url
                                };
                                break;
                            }
                        }
                        return updatedFiles;
                    });
                    /**
                     * Resolve the promise
                     */
                    resolve(url);
                });
            });
        });
    };

    /**
     * Attach a listener onto the upload job to stream the progress into state
     *
     * @param {object} upload Upload object reference on the storage item
     * @param {string} fileID ID of the file to be monitored
     */
    const monitorUploadProgress = (upload, fileID) => {
        return new Promise((resolve, reject) => {
            /**
             * Setup a listener on the upload process
             */
            upload.on("state_changed", (snapshot) => {
                /**
                 * Work out a progress percentage
                 */
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                /**
                 * Update the state with the progress of this file
                 */
                setUploadFiles((files) => {
                    let updatedFiles = [...files];
                    for (let i in files) {
                        if (files[i].id === fileID) {
                            updatedFiles[i] = {
                                ...files[i],
                                upload: {
                                    ...files[i].upload,
                                    progress: progress,
                                }
                            };
                            break;
                        }
                    }
                    return updatedFiles;
                });
            }, (error) => console.log(error), () => {
                resolve();
            });
        });
    };

    return (
        <div className="content-upload-form">
            {/* Show a title to the modal but only if it's the first file to be uploaded */}
            {!firstUpload &&
                <h1>Upload Content <div onClick={() => props.toggleUpload()}><TimesIcon /></div></h1>
            }

            {/* Form inputs */}
            <div className="upload-form-inputs">
                <Input
                    value={contentTitle}
                    onChange={setContentTitle}
                    placeholder="Content title:" />

                <Select
                    placeholder="Upload type"
                    value={uploadType.value}
                    onSelect={setUploadType}
                    options={{
                        image: "Image",
                        [`video${!isPremium ? "_isLocked" : ""}`]: "Video",
                        [`website_url${!isPremium ? "_isLocked" : ""}`]: "Website URL",
                    }} />

                {/* Is the content type either an image or video format? */}
                {(uploadType.option === "image" || uploadType.option === "video") &&
                    <>
                        <FileDrop
                            onDrop={(files, e) => handleFilesDropped(files, e)}
                            onTargetClick={(e) => checkForValidClick(e)}
                            targetClassName={["file-drop-target", (uploadFiles.length > 0) && "has-files"].join(" ")}>
                            {/* Are there any files to display in the list? */}
                            {(uploadFiles.length > 0) &&
                                uploadFiles.map((file) => (
                                    <div key={file.id} className={["file-row", file.error.message ? "error" : ""].join(" ")}>
                                        <div className="content">
                                            <p>{file.file.name}</p>

                                            {/* If we are not uploading, show a trash icon to remove the file */}
                                            {!uploading &&
                                                <div id="remove-file" onClick={() => removeFileFromList(file.id)}>
                                                    <TrashIcon />
                                                </div>
                                            }
                                        </div>

                                        {/* Upload progress */}
                                        <div className={["progress", file.upload.progress === 100 && "done"].join(" ")}
                                            style={{ width: `${file.upload.progress}%` }}>

                                            <small>
                                                {/* Is there an error on this file? */}
                                                {file.error.message &&
                                                    <>{file.error.message}</>
                                                }

                                                {/* Are a progress number and error both not present? */}
                                                {(!file.error.message && !file.upload.progress) &&
                                                    <>Pending Upload</>
                                                }

                                                {/* Is there a progress number for the upload and an error isn't present? */}
                                                {(!file.error.message && file.upload.progress) &&
                                                    <>{file.upload.progress === 100 ? "Done" : `${file.upload.progress}%`}</>
                                                }
                                            </small>
                                        </div>
                                    </div>
                                ))
                            }

                            {/* If no files have been added yet */}
                            {(uploadFiles.length === 0) &&
                                <div className="file-drop-placeholder">
                                    <h1>Drop or click to add files</h1>
                                    {/* Show the image format suggestions? */}
                                    {(uploadType.option === "image") &&
                                        <h2>.png, .jpg, .jpeg &amp; .pdf formats supported</h2>
                                    }

                                    {/* Or the video format suggestions */}
                                    {(uploadType.option === "video") &&
                                        <h2>.mp4 &amp; .mov formats supported</h2>
                                    }
                                </div>
                            }

                            {/* Inputfield for allowing clicking on the media window */}
                            <input ref={inputRef} type="file" onChange={handleFileClicked} style={{ display: "none" }} />
                        </FileDrop>
                    </>
                }

                {/* Is the content type a website url? */}
                {(uploadType.option === "website_url") &&
                    <>
                        <Input
                            value={websiteURL}
                            onChange={setWebsiteURL}
                            placeholder="Url to fetch:"
                            error={websiteURLError} />

                        <MultipleSelect
                            placeholder="Capture sizes"
                            onSelect={setWebsiteCaptureSizes}
                            options={screen_sizes} />
                    </>
                }

                <Button
                    label="Upload"
                    loading={uploading}
                    loadingText="Uploading..."
                    disabled={!((websiteURL && websiteCaptureSizes.length > 0) || uploadFiles.length > 0) || !contentTitle}
                    onClick={() => uploadContent()} />
            </div>
        </div>
    );
}

export default Upload;