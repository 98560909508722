import React, { useEffect, useState, useContext } from "react";
import { db, arrayRemove, arrayUnion, getTimestamp } from "../../../../utils/firebase";
import { ModalContext } from "../../../../utils/providers/modal";
import "./stakeholder.scss";

/**
 * Hooks
 */
import useProfilePicture from "../../../../hooks/useProfileImage";

/**
 * UI components
 */
import Permission from "./permission";
import Project from "./project";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

/**
 * 
 *
 * @param {object} props Props object passed down, should contain a userID and clientID
 * @returns HTML markup for the user list item to display on the clients 'Assigned Users' tab
 */
function ClientStakeholder(props) {
    const [toggled, setToggled] = useState(false);
    const [user, setUser] = useState({});
    const [userClient, setUserClient] = useState({});
    const [permissions, setPermissions] = useState([]);
    const [permissionString, setPermissionString] = useState("");

    /**
     * Deconstruct the showModal function from the Modal context
     */
    const { showModal } = useContext(ModalContext);

    /**
     * Deconstruct the IDs we need from the props
     */
    const { userID, clientID } = props;

    /**
     * Get the user profile picture 
     */
    const userProfilePicture = useProfilePicture(userID);

    /**
     * when the userID is loaded from props
     */
    useEffect(() => {
        /**
         * Get the users document from the database
         */
        const unsubscribe = db.doc(`users/${userID}`).onSnapshot((userDoc) => {
            /**
             * Do they exist?
             */
            if (userDoc.exists) {
                /**
                 * Update the state with the details
                 */
                setUser(userDoc.data());
            } else {
                /**
                 * Otherwise just push a false statement
                 */
                setUser(false);
            }
        });
        /**
         * Remove the listener when the component unmounts
         */
        return () => unsubscribe();
    }, [userID]);

    /**
     * When both the userID and clientID are available from props
     */
    useEffect(() => {
        /**
         * Then get the client record on this users document
         */
        db.doc(`users/${userID}/clients/${clientID}`).get().then((clientDoc) => {
            /**
             * Does this user have a record of the client in their collection
             */
            if (clientDoc.exists) {
                /**
                 * Update the state with the details
                 */
                setUserClient(clientDoc.data());
                /**
                 * Add the permissions to the state
                 */
                setPermissions(clientDoc.data().permissions);
            } else {
                /**
                 * Otherwise just push a false statement
                 */
                setUserClient(false);
            }
        });
    }, [userID, clientID]);

    /**
     * On update of the permissions, re-generate the string
     */
    useEffect(() => {
        /**
         * Push the various permissions into an array
         */
        let perms = [];
        if (permissions.FEEDBACK) {
            perms.push("Feedback & Approve");
        }
        if (permissions.COLLABORATE) {
            perms.push("Collaborate");
        }
        if (permissions.ADMINISTRATE) {
            perms.push("Administrate");
        }
        /**
         * Update the state
         */
        setPermissionString(perms.length > 0 ? perms.join(", ") : "No Permissions");
    }, [permissions]);

    /**
     * Ran when the user primary tab of the list item is clicked
     *
     * @type {const}
     */
    const toggleUserView = (e) => {
        /**
         * Make sure the delete button wasn't the target fo the click
         */
        if (e.target.id !== "remove") {
            /**
             * Toggle the view state
             */
            setToggled((toggled) => !toggled);
        }
    };

    /**
     * Runs through some logic for toggling the user permissions
     *
     * @type {const}
     * @param {string} type Type of permission to update for the user
     */
    const toggleUserPermission = (type) => {
        /**
         * Update the database with the new permission
         */
        db.doc(`users/${props.userID}/clients/${props.clientID}`).update({
            [`permissions.${type}`]: !permissions[type],
        });
        /**
         * Update the state to reflect new permissions
         */
        setPermissions({ ...permissions, [type]: !permissions[type] });
    };

    /**
     *
     *
     * @type {const}
     * @param {string} projectID ID of the project the action is to be performed on
     */
    const toggleUserProjectAccess = async (projectID) => {
        /**
         * Does this person currently have access to the project
         */
        const hasAccess = user.projects?.includes(projectID);
        /**
         * If they currently have access, then we are removing it
         */
        if (hasAccess) {
            db.doc(`users/${props.userID}`).set({
                projects: arrayRemove(projectID),
            }, { merge: true });
            /**
             * Remove the project document from their subcollection
             */
            db.doc(`users/${props.userID}/projects/${projectID}`).delete();
            /**
             * We also need to remove the requirement for their approval
             */
            db.doc(`clients/${props.clientID}/projects/${projectID}`).set({
                users: arrayRemove(props.userID),
                requiresApproval: arrayRemove(props.userID),
            }, { merge: true });
        } else {
            /**
             * Otherwise we are granting them permission
             */
            db.doc(`users/${props.userID}`).set({
                projects: arrayUnion(projectID),
            }, { merge: true });
            /**
             * Add the project document to the users subcollection
             */
            const timestamp = await getTimestamp();
            db.doc(`users/${props.userID}/projects/${projectID}`).set({
                client: props.clientID,
                since: timestamp,
            }, { merge: true });
            /**
             * We also need to add them from the project itself
             */
            db.doc(`clients/${props.clientID}/projects/${projectID}`).set({
                users: arrayUnion(props.userID),
            }, { merge: true });
        }
    };

    /**
     * 
     */
    const checkToRemoveFromClient = () => {
        showModal({
            type: "ERROR",
            title: "Are you sure?",
            body: "Removing the user will mean they no longer have access to this client.",
            cancel: {
                label: "Cancel",
                action: () => { return false }
            },
            next: {
                label: "Yes, please",
                action: removeUserFromClient
            }
        });
    }

    /**
     * Remove the user from the client account
     *
     * @type {const}
     */
    const removeUserFromClient = () => {
        /**
         * Update the database documents
         */
        db.doc(`users/${userID}/clients/${clientID}`).delete();
        db.doc(`clients/${clientID}/users/${userID}`).delete();
        db.doc(`clients/${clientID}`).update({ users: arrayRemove(userID) });
    };

    return (
        <div className={["userli", toggled ? "open" : ""].join(" ")}>
            {/* Primary user details */}
            <div className="userli-primary" onClick={(e) => toggleUserView(e)}>
                <div className="userli-image">
                    <div className="userli-image-wrap">
                        {userProfilePicture}
                    </div>
                </div>
                <div className="userli-name">
                    <p>{user.name || "Anonymous User"}</p>
                </div>
                <div className="userli-email">
                    <p>{user.email}</p>
                </div>
                <div className="userli-permissions">
                    <p>{permissionString}</p>
                </div>
                {props.canRemove && (
                    <div className="userli-remove" id="remove" onClick={() => checkToRemoveFromClient()}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                )}
                <div className="userli-chev">
                    <FontAwesomeIcon icon={toggled ? faCaretUp : faCaretDown} />
                </div>
            </div>
            {/* Secondary user details (visible when the primary tab is clicked) */}
            <div className="userli-secondary">
                {/* Permissions */}
                <div className="userli-heading">
                    <h1>Permissions</h1>
                </div>
                <div className="userli-permissions-block">
                    <Permission toggled={permissions.FEEDBACK} toggle={() => toggleUserPermission("FEEDBACK")}>
                        <h1>Feedback &amp; approve</h1>
                        <p>Allows access for user to view projects/content and add comments</p>
                    </Permission>
                    <Permission toggled={permissions.COLLABORATE} toggle={() => toggleUserPermission("COLLABORATE")}>
                        <h1>Collaborate</h1>
                        <p>Allows access for user to upload and remove projects and content</p>
                    </Permission>
                    <Permission toggled={permissions.ADMINISTRATE} toggle={() => toggleUserPermission("ADMINISTRATE")}>
                        <h1>Administrate</h1>
                        <p>Allows access for user to edit client details and remove client account</p>
                    </Permission>
                </div>
                {/* Projects */}
                <div className="userli-heading">
                    <h1>Projects</h1>
                </div>
                <div className="userli-projects-block">
                    <div className="userli-project">
                        <div className="userli-project-title">
                            <p>Project title</p>
                        </div>
                        <div className="userli-project-toggles">
                            <p>Can feedback</p>
                            <p>Approval required</p>
                        </div>
                    </div>
                    {props.projects.map((project) => (
                        <Project
                            key={project.id}
                            title={project.title}
                            userID={props.userID}
                            clientID={props.clientID}
                            projectID={project.id}
                            allowed={user.projects?.includes(project.id)}
                            required={user.projects?.includes(project.id)}
                            toggleAccess={() => toggleUserProjectAccess(project.id)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ClientStakeholder;
