import React, { useEffect, useState, useContext } from "react";
import { db, fun } from "../../../utils/firebase";
import { ModalContext } from "../../../utils/providers/modal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import moment from "moment";
import "./billing.scss";

/**
 * Card icons
 */
import Visa from "../../../assets/images/visa.png";
import Mastercard from "../../../assets/images/mastercard.png";

/**
 * UI components
 */
import Button from "../../design-system/ui/button/button";
import Badge from "../../design-system/ui/badge/badge";

/**
 * Functional component to return the HTML markup for the agency billing in scope
 */
function BillingDetails(props) {
    const [billing, setBilling] = useState(null);
    const [customerID, setCustomerID] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [addingCard, setAddingCard] = useState(false);
    const [cardError, setCardError] = useState("");
    const [subscription, setSubscription] = useState(false);
    const [cancelingSubscription, setCancelingSubscription] = useState(false);
    const [resumingSubscription, setResumingSubscription] = useState(false);

    /**
     * Get the agencyID from the props
     */
    const { agencyID } = props;

    /**
     * Deconstruct the showModal function from the modal context
     */
    const { showModal } = useContext(ModalContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the agencies database document
         */
        const unsubscribe = db.doc(`agencies/${agencyID}`).onSnapshot((agencySnap) => {
            /**
             * Make sure the agency 
             */
            if (agencySnap.exists) {
                /**
                 * Get the billing details from the agency document
                 */
                const { stripe, subscription } = agencySnap.data();
                /**
                 * Set the billing details into the state
                 */
                setBilling(stripe || null);
                setCustomerID(stripe.customerID || null);
                setSubscription(subscription || null);
            }
        });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the invoices subcollection for the agency
         */
        const unsubscribe = db.collection(`agencies/${agencyID}/invoices`)
            .orderBy("created", "desc")
            .limit(6)
            .onSnapshot((invoicesSnap) => {
                /**
                 * Loop over the changes passed down
                 */
                invoicesSnap.docChanges().forEach((change) => {
                    /**
                     * Invoice item added
                     */
                    if (change.type === "added") {
                        setInvoices((invoices) => [
                            ...invoices,
                            {
                                id: change.doc.id,
                                ...change.doc.data(),
                            }
                        ]);
                    }
                    /**
                     * Invoice item modified
                     */
                    if (change.type === "modified") {
                        setInvoices((invoices) => {
                            let updatedInvoices = [...invoices];
                            for (let i in invoices) {
                                if (invoices[i].id === change.doc.id) {
                                    updatedInvoices[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedInvoices;
                        });
                    }
                    /**
                     * Invoice item removed
                     */
                    if (change.type === "removed") {
                        setInvoices((invoices) => invoices.filter((invoice) => invoice.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * Use the stripe react hooks
     */
    const stripe = useStripe();
    const elements = useElements();

    /**
     * Handle the submission of the card elements form
     */
    const addPaymentSource = async () => {
        /**
         * Update the UI
         */
        setAddingCard(true);
        setCardError("");
        /**
         * Make sure we have loaded the stripe libaries correctly
         */
        if (!stripe || !elements) {
            return;
        }
        /**
         * Build the card element object form the form field
         */
        const cardElement = elements.getElement(CardElement);
        /**
         * Create the source with stripe
         */
        const { error, source } = await stripe.createSource(cardElement);
        /**
         * If there was an error
         */
        if (error) {
            console.log(error);
        } else {
            /**
             * Payment method updated
             */
            const updatePaymentSource = fun.httpsCallable("paymentSourceUpdated");
            const paymentSourceResult = await updatePaymentSource({
                customer: customerID,
                source,
                agencyID,
            });
            console.log(paymentSourceResult);
            /**
             * Was there an error passed back?
             */
            if (paymentSourceResult.data.error) {
                /**
                 * Push it into the state to show in the dom
                 */
                setCardError(paymentSourceResult.data.error);
            }
        }
        /**
         * Update the UI
         */
        setAddingCard(false);
    };

    /**
     * Remove the payment method from the agencies account
     */
    const removePaymentSource = async () => {
        /**
         * Call the cloud function to remove the
         */
        const removePaymentSource = fun.httpsCallable("paymentSourceRemoved");
        await removePaymentSource({
            customer: customerID,
            source: billing.payment_source,
            agencyID,
        });
    }

    /**
     * Show a modal before cancelling the subscription to notify of the licenses being removed
     */
    const preCancellation = () => {
        /**
         * Show a modal for confirmation first
         */
        showModal({
            type: "ALERT",
            title: "Are you sure?",
            body: "By canceling your subscription, all users for your agency on premium licenses will lose their functionality.",
            cancel: {
                label: "Cancel",
                action: () => { return null; },
            },
            next: {
                label: "Yes, please",
                action: () => cancelSubscription(),
            }
        });
    }

    /**
     * Cancel the subscription through a cloud function invokation
     */
    const cancelSubscription = async () => {
        /**
         * Show a spinner on the cancel button
         */
        setCancelingSubscription(true);
        /**
         * Call the cloud function to remove the
         */
        const cancelSubscriptionFunc = fun.httpsCallable("subscriptionCancel");
        await cancelSubscriptionFunc({
            agencyID,
            subscriptionID: subscription.subscriptionID,
        });
        /**
         * Hide the spinner
         */
        setCancelingSubscription(false);
    }

    return (
        <>
            <div className="edit-details-block">
                <h2>Payment Method</h2>
                <p>Add/update your payment card</p>

                {/* Is there no billing object setup for this agency? */}
                {!billing?.payment_source &&
                    <>
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        color: "#4E5C70",
                                        fontFamily: '"Rubik", sans-serif',
                                        fontSmoothing: "antialiased",
                                        fontSize: "15px",
                                        fontWeight: "400",
                                        "::placeholder": {
                                            fontSize: "14px",
                                            opacity: "0.5",
                                            color: "#4E5C70",
                                        },
                                    },
                                    invalid: {
                                        color: "#fa755a",
                                        iconColor: "#fa755a",
                                    },
                                },
                            }}
                        />

                        {/* Was there an error adding the card? */}
                        {cardError &&
                            <p className="source-addition-error">{cardError}</p>
                        }

                        <Button
                            label="Add Card"
                            disabled={!stripe || addingCard}
                            loading={addingCard}
                            loadingText="Adding Card..."
                            onClick={() => addPaymentSource()} />
                    </>
                }

                {/* If there is a payment source present */}
                {billing?.payment_source &&
                    <div className="billing-card">
                        <div className="bc-brand">
                            {/* Visa card icon */}
                            {(billing?.billing.brand === "Visa") &&
                                <img src={Visa} alt="Card Brand" />
                            }

                            {/* Mastercard card icon */}
                            {(billing?.billing.brand === "Mastercard") &&
                                <img src={Mastercard} alt="Card Brand" />
                            }
                        </div>
                        <div className="bc-number">
                            <p>{billing?.billing.brand} .... {billing?.billing.last4}</p>
                            <small>Expires {billing?.billing.expiry}</small>
                        </div>
                        <div className="bc-remove">
                            <p onClick={() => removePaymentSource()}>remove</p>
                        </div>
                    </div>
                }
            </div>

            <div className="edit-details-block">
                <h2>Subscription</h2>
                <p>Details about your subscription licenses with us</p>
                {/* If there is no subscription setup for this agency */}
                {!subscription?.subscriptionID &&
                    <small>No active subscription setup, add a license to a user to get started</small>
                }
                {/* If there is a subscription active for the agency */}
                {subscription?.subscriptionID &&
                    <div className="cancel-subscription-block">
                        {/* Subscription licenses & price */}
                        <div className="subscription-licenses">
                            <p>{subscription.licences} license{(subscription.licences > 1 || subscription.licences === 0) ? "s" : ""}</p>
                            <span>£{subscription.licences * 8} monthly</span>
                        </div>

                        {/* Has the subscription already been canceled, show the resume button and the cancel period */}
                        {subscription.cancelled &&
                            <>
                                <p className="subscription-period-end">Your subscription was cancelled and will expire on {moment(subscription.period_end, "X").format("DD/MM/YYYY")}</p>
                                <p className="subscription-period-end">To resume, assign a premium license to a user</p>
                            </>
                        }

                        {/* Is the subscription not yet canceled, show the cancel button */}
                        {!subscription.cancelled &&
                            <Button
                                label="Cancel subscription"
                                onClick={() => preCancellation()}
                                className="NEGATIVE"
                                loading={cancelingSubscription}
                                loadingText="Cancelling your subscription" />
                        }
                    </div>
                }
            </div>

            <div className="edit-details-block">
                <h2>Invoices</h2>
                <p>A list of your 6 most recent invoices</p>
                <table className="invoices-table">
                    <thead>
                        <tr>
                            <th>Invoice number</th>
                            <th>Date raised</th>
                            <th>Due date</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((invoice) => (
                            <tr key={invoice.id}>
                                <td>
                                    <p>{invoice.id === "_UPCOMING" ? "Upcoming" : invoice.number}</p>
                                </td>
                                <td>
                                    <p>{moment(invoice.created, "X").format("DD/MM/YYYY")}</p>
                                </td>
                                <td>
                                    {/* If there is a due date present for the invoice */}
                                    {invoice.due_date &&
                                        <p>{moment(invoice.due_date, "X").format("DD/MM/YYYY")}</p>
                                    }

                                    {/* If there isn't we'll assume it's to be charged immediately */}
                                    {!invoice.due_date &&
                                        <p>{moment(invoice.created, "X").format("DD/MM/YYYY")}</p>
                                    }
                                </td>
                                <td>
                                    <p>£{invoice.amount_due / 100}</p>
                                </td>
                                <td>
                                    {(invoice.status === "draft") && <Badge label="Upcoming" />}
                                    {(invoice.status === "void") && <Badge label="Void" />}
                                    {(invoice.status === "open") && <Badge type="INFO" label="Open" />}
                                    {(invoice.status === "paid") && <Badge type="POSITIVE" label="Paid" />}
                                    {(invoice.status === "failed") && <Badge type="NEGATIVE" label="Failed" />}
                                    {(invoice.status === "uncollectible") && <Badge type="NEGATIVE" label="Uncollectable" />}
                                </td>
                                <td className="invoice-action-buttons">
                                    {/* If there is a hosted invoice available */}
                                    {invoice.invoice_url &&
                                        <a href={invoice.invoice_url} target="_blank" rel="noopener noreferrer">
                                            View
                                        </a>
                                    }

                                    {/* If there is a invoice PDF available */}
                                    {invoice.invoice_pdf_url &&
                                        <a href={invoice.invoice_pdf_url} target="_blank" rel="noopener noreferrer">
                                            Download
                                        </a>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

/**
 * Load the Stripe APIs with the correct public keys
 */
let stripePubKey;
if (window.location.hostname === "app.feeval.com") {
    /**
     * Get the live stripe key
     */
    stripePubKey = "pk_live_e1D4oKEJXvcrPWcPrSdr7Dvg00KCIb9KCI";
} else {
    /**
     * Get the development stripe key
     */
    stripePubKey = "pk_test_Rd0zw2JsNKRNSW0filKUiFFj00Q5oy6Emb";
}

/**
 * Create the stripe promise
 */
const stripePromise = loadStripe(stripePubKey);

/**
 * Return method for the BillingDetails object wrapped in the stripe promise
 */
function Billing(props) {
    return (
        <Elements stripe={stripePromise}>
            <BillingDetails agencyID={props.agencyID} />
        </Elements>
    );
};

export default Billing;