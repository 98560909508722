import React from "react";
import moment from "moment";

import Card from "../../ui/card/card";
import Head from "../../ui/card/head";
import Block from "../../ui/card/block";


/**
 * 
 * @param {object} props Props object should contain a clientID and projectID for setting up the listener
 * @returns HTML markup for the meta card to display alongside the content timeline 
 */
function Meta(props) {

    /**
     * Deconstruct the IDs we need from the props
     */
    const { clientID, projectID, project } = props;

    /**
     * Deconstruct the project data
     */
    const { title, approved, download, created, updated } = project;
    // console.log(created)

    return (
        <div className="project-meta">
            <Card>
                <Head type="large">
                    <h1>Project Details</h1>
                </Head>
                <Block>
                    <table className="project-meta-table">
                        <tbody>
                            <tr>
                                <td className="table-title">Title</td>
                                <td>{title}</td>
                            </tr>
                            <tr>
                                <td className="table-title">Started</td>
                                <td>{moment.unix(created?.seconds).format("DD/MM/YYYY hh:mma")}</td>
                            </tr>
                            <tr>
                                <td className="table-title">Last Updated</td>
                                <td>{moment.unix(updated?.seconds).format("DD/MM/YYYY hh:mma")}</td>
                            </tr>
                            <tr>
                                <td className="table-title">Total Revisions</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td className="table-title">Total Comments</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td className="table-title">Approved</td>
                                <td>{approved ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                                <td className="table-title">Download Enabled</td>
                                <td>{download ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                                <td className="table-title">Stakeholders</td>
                                {/* <td className="project-stakeholders">
                                    {this.state.users ?
                                        this.state.users.map(user => (
                                            <div className="project-user" key={user.email}>
                                                <div className={['tooltip'].join(' ')}>
                                                    <div className="tooltip-text">
                                                        <p>{user.name || user.email}</p>
                                                    </div>
                                                </div>
                                                <div className="profile-image-wrap">
                                                    <img src={user.imageURL || placeholder} alt={user.name || user.email} />
                                                </div>
                                            </div>
                                        )) : null}
                                </td> */}
                            </tr>
                            <tr>
                                <td className="table-title">Tasks</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </Block>
            </Card>
        </div>
    );
}

export default Meta;