import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import { db } from "../../../utils/firebase";
import "../project.scss";

/**
 * Hooks
 */
import useImageCollage from "../../../hooks/useImageCollage";

/**
 * Functional component to deconstruct the project data into a card to display in the DOM
 * 
 * @param {object} props Project data passed down from the parent database listener 
 * @returns HTML markup for the project information 
 */
function Project(props) {
    const [title, setTitle] = useState("");
    const [approved, setApproved] = useState(false);
    const [contentCount, setContentCount] = useState(0);
    const [thumbnails, setThumbnails] = useState([]);

    /**
     * Deconstruct the client ID and project data from the props
     */
    const { clientID, projectID } = props;

    /**
    * Get the preview thumbnails HTML
    */
    const previewGrid = useImageCollage(thumbnails);

    /**
     * With a project ID present...
     */
    useEffect(() => {
        /**
         * Setup a listener on the projects "content" collection to get the total count
         */
        const unsubscribe = db.doc(`clients/${clientID}/projects/${projectID}`)
            .onSnapshot((projectSnap) => {
                /**
                 * Deconstruct the data needed from the project
                 */
                const { title, approved, previewThumbnails } = projectSnap.data();
                /**
                 * Update the state with the data
                 */
                setTitle(title);
                setApproved(approved);
                setThumbnails(previewThumbnails);
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * With a project ID present...
     */
    useEffect(() => {
        /**
         * Setup a listener on the projects "content" collection to get the total count
         */
        const unsubscribe = db.collection(`clients/${clientID}/projects/${projectID}/content`)
            .onSnapshot((contentSnap) => {
                /**
                 * Update the local state with the content snap size
                 */
                setContentCount(contentSnap.size);
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    return (
        <div className="project-card">
            {/* Project collage of recent content */}
            <div className="project-collage">
                {/* Preview grid of thumbnails for the project */}
                {previewGrid}
            </div>

            {/* Project title */}
            <p className="project-title">{title}</p>

            {/* Stat figures & approval status */}
            <div className="project-stats">
                <div className="stat">
                    <p>
                        {approved ? <small className="green">Yes</small> : <small className="red">No</small>}
                        <span>Approved</span>
                    </p>
                </div>
                <div className="stat">
                    <p>
                        {contentCount}
                        <span>Content Uploads</span>
                    </p>
                </div>
            </div>

            {/* Manage link to project page */}
            <div className="project-manage">
                <NavLink to={`/project/${projectID}/timeline`} className="ui-button info" activeClassName="active">
                    Manage
                </NavLink>
            </div>
        </div >
    );
}

export default Project;