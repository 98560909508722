import React, { useEffect, useState, useContext } from "react";
import { ContentProvider, ContentContext } from "../../utils/providers/content";
import { db, auth, arrayUnion } from "../../utils/firebase";
import "./content.scss";

/**
 * UI blocks & content types
 */
import ImageContainer from "./types/image";
import VideoContainer from "./types/video";
import ContentMeta from "../../components/content/meta/meta";
import Comments from "../../components/content/comments/comments";
import FeedbackTools from "../../components/content/feedback/tools";

/**
 * Functional component to handle the processing and display of the content and the feedback that sits underneath it
 *
 * @returns HTML markup for the content view page with functionality for locking out unwanted users, or those
 * who don't have permission to view the particular content file.
 */
function ContentTile(props) {
    const [loading, setLoading] = useState(true);

    /**
     * Deconstruct the ViewID from the props
     */
    const { viewID } = props;

    /**
     * Get the required update methods for the content context provider
     */
    const { setIDs, content, setContent } = useContext(ContentContext);

    /**
     * Setup a variable to hold the removal cloud function URL
     */
    let removeFunctionBase;
    if (window.location.hostname === "app.feeval.com") {
        /**
         * Get the live removal function
         */
        removeFunctionBase = "https://us-central1-feevalapp.cloudfunctions.net/removeActiveUser";
    } else {
        /**
         * Get the development removal function
         */
        removeFunctionBase = "https://us-central1-feeval-dev.cloudfunctions.net/removeActiveUser";
    }

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Mount an event to the visibility change of the window
         */
        document.addEventListener("visibilitychange", () => {
            /**
             * Is the tab status hidden (either the tab has been closed or the user has switched tabs 
             * in their browser)
             */
            if (document.visibilityState === "hidden") {
                /**
                 * If the tab has been closed
                 */
                navigator.sendBeacon(`${removeFunctionBase}?viewID=${viewID}&userID=${auth.currentUser.uid}`);
            } else {
                /**
                 * If the tab has come back into focus
                 */
                auth.currentUser?.uid && db.doc(`content/${viewID}`).set({
                    activeUsers: arrayUnion(auth.currentUser.uid),
                }, { merge: true });
            }
        });
        /**
         * Remove the event listener
         */
        return () => {
            window.removeEventListener("visibilitychange");
        }
    }, []);

    useEffect(() => {
        /**
         * Before unload event should catch the event of a back button press
         */
        window.addEventListener("beforeunload", () => {
            /**
             * Fire off a call to remove the user from the activeUsers
             */
            navigator.sendBeacon(`${removeFunctionBase}?viewID=${viewID}&userID=${auth.currentUser.uid}`);
        });
        /**
         * Remove the event listener
         */
        return () => {
            window.removeEventListener("beforeunload");
        }
    }, []);

    /**
     * When the auth object is updated for the current user
     */
    useEffect(() => {
        /**
         * Add the user to the activeUsers array for the content
         */
        auth.currentUser?.uid && db.doc(`content/${viewID}`).set({
            activeUsers: arrayUnion(auth.currentUser.uid),
        }, { merge: true });
    }, [auth.currentUser]);

    /**
     * When the viewID value is updated
     */
    useEffect(() => {
        /**
        * Call the database and get the content details at the viewID address
        */
        db.doc(`content/${viewID}`).get().then((contentDoc) => {
            /**
             * If the content document can be found
             */
            if (contentDoc.exists) {
                /**
                 * Deconstruct the IDs required from the data
                 */
                const { client, project, content } = contentDoc.data();
                /**
                 * Update the local state with these IDs
                 */
                setIDs({ client, project, content, file: viewID });
                /**
                 * Then attempt to fetch the content document itself
                 */
                db.doc(`clients/${client}/projects/${project}/content/${content}`).get().then((contentData) => {
                    /**
                     * Make sure the content document exists
                     */
                    if (contentData.exists) {
                        /**
                         * Set the content context with the required data
                         */
                        setContent(contentData.data());
                    }
                });
            }
        });
    }, [viewID]);

    return (
        <>
            {loading &&
                <div className="content-loading-cover">
                    loading
                </div>
            }

            <div id="content-tile-wrapper">
                {/* Image formats */}
                {content?.type.format === "image" && <ImageContainer onLoad={() => setLoading(false)} />}
                {/* Video formats */}
                {content?.type.format === "video" && <VideoContainer onLoad={() => setLoading(false)} />}

                {/* Feedback tools selector */}
                <FeedbackTools />

                {/* Content meta wrapper */}
                <div className="ct-extra">
                    {/* Meta data */}
                    <ContentMeta viewID={viewID} />

                    {/* Comments panel */}
                    <Comments />
                </div>
            </div>
        </>
    );
}

/**
 * Functional component hat just wraps the content logic in a context
 */
function Content(props) {
    return (
        <ContentProvider>
            <ContentTile viewID={props.match.params?.id} />
        </ContentProvider>
    );
}

export default Content;
