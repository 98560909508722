import React, { useEffect, useState, useContext } from "react";
import { db, auth, fun } from "../../../utils/firebase";
import { AlertsContext } from "../../../utils/providers/alerts";

/**
 * UI components
 */
import SearchSelect from "../../design-system/ui/select/search-select";
import Button from "../../design-system/ui/button/button";

/**
 *
 *
 * @returns HTML markup for the inviting new users to the client
 */
function InviteUser(props) {
    const [inviteEmail, setInviteEmail] = useState("");
    const [adding, setAdding] = useState(false);
    const [agencyUsers, setAgencyUsers] = useState({});

    /**
     * Deconstruct the pushAlert function from the alerts context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Make a call to the agencies database document
         */
        db.doc(`agencies/${props.agencyID}`).get().then((agencyDoc) => {
            /**
             * Make sure the agency exists
             */
            if (agencyDoc.exists) {
                /**
                 * Get the users saved on the agency document
                 */
                const { users } = agencyDoc.data() || [];
                /**
                 * Loop over the users assigned to this agency
                 */
                users.forEach(async (user) => {
                    /**
                     * Don't push our own ID onto the available
                     */
                    if (!props.currentUsers.includes(user)) {
                        /**
                         * Get the users data from the database
                         */
                        db.doc(`users/${user}`).get().then((userDoc) => {
                            /**
                             * If this user still exists
                             */
                            if (userDoc.exists) {
                                /**
                                 * Deconstruct their email
                                 */
                                const { email } = userDoc.data();
                                /**
                                 * Update the state with their details
                                 */
                                setAgencyUsers({ ...agencyUsers, [user]: email });
                            }
                        });
                    }
                });
            }
        });
    }, [props.currentUsers]);

    /**
     *
     *
     * @type {const}
     */
    const assignEmailToClient = async () => {
        /**
         * Update the state
         */
        setAdding(true);
        /**
         * Make a call to the cloud function to start the invite processes
         */
        const inviteUser = fun.httpsCallable("assignEmailToClient");
        inviteUser({
            email: inviteEmail,
            agencyID: props.agencyID,
            clientID: props.clientID,
            addedBy: auth.currentUser.uid,
        });
        /**
         * Show an alert to say the action is complete
         */
        pushAlert({
            title: "User Assigned",
            body: `We're adding ${inviteEmail} to the client and letting them know with via email.`
        });
        /**
         * Update the state
         */
        setAdding(false);
        setInviteEmail("");
    };

    /**
     * Ran when a user is selected from the available drop down list of agency users
     *
     * @type {const}
     * @param {string} userID ID of the user that was selected from the dropdown list
     */
    const assignAgencyUserToClient = (userID) => {
        /**
         * Make a call to the cloud function to assign the user
         */
        const assignAgencyUser = fun.httpsCallable("assignAgencyUserToClient");
        assignAgencyUser({
            userID: userID,
            agencyID: props.agencyID,
            clientID: props.clientID,
            addedBy: auth.currentUser.uid,
        });
    };

    /**
     * when a agency user is selected from the dropdown list, get their user ID from the option object 
     * and call the invite cloud function to off-load the work
     * 
     * @type {const}
     * @param {object} option Chosen option as object format passed from select input
     */
    const userEmailSelected = (option) => {
        /**
         * Reset the invite email to null
         */
        setInviteEmail("");
        /**
         * Use the userID from the option selected to call the assign function
         */
        assignAgencyUserToClient(option.option);
        /**
         * Show an alert to say the action is complete
         */
        pushAlert({
            title: "User Assigned",
            body: `We're adding ${option.value} to the client and letting them know with an email, if they have notifications turned on.`
        });
    }

    return (
        <div className="invite-user-window">
            <SearchSelect
                placeholder="User email"
                hideArrow={true}
                value={inviteEmail}
                options={agencyUsers}
                onSelect={(option) => userEmailSelected(option)}
                onTextChange={(email) => setInviteEmail(email)}
            />

            <Button
                loading={adding}
                loadingText="Assigning..."
                label="Assign"
                onClick={() => assignEmailToClient()}
                disabled={inviteEmail.length === 0} />
        </div>
    );
}

export default InviteUser;
