import React, { useState } from "react";
import firebase from "firebase";
import { db, arrayUnion } from "../../utils/firebase";
import { defaultNotifications } from '../../utils/notifications';
import { NavLink } from "react-router-dom";
import "./signup.scss";

/**
 * UI components
 */
import FeevalLogo from "../../assets/images/feeval-logo.png";
import { EyeOpenIcon, EyeSlashedIcon } from "../../utils/svgs";
import { ReactComponent as SideAccents } from "../../assets/images/signup-accents.svg";
import Input from "../../components/design-system/ui/inputs/input";
import Button from "../../components/design-system/ui/button/button";

/**
 * Functional component to return the signup page
 */
function Signup() {
    const [fullName, setFullName] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showingPassword, setShowingPassword] = useState(false);

    /**
     * Check the inputs from the user to make sure they are all valid before we start ot set 
     * them up with a user account and sync them into stripe
     */
    const checkUserInputs = () => {
        /**
         * Show the user we are loading and reset the errors
         */
        setLoading(true);
        setErrors({});
        /**
         * Setup an object for holding any errors
         */
        let inputErrors = {};
        /**
         * Is there a valid full name present
         */
        if (fullName.length < 3) {
            inputErrors["fullName"] = "Please enter a valid name";
        }
        /**
         * Is there a valid business name present
         */
        if (businessName.length < 3) {
            inputErrors["businessName"] = "Please enter a valid business title";
        }
        /**
         * Check the email against a regex for a valid email syntax
         */
        const checkEmail = /\S+@\S+\.\S+/;
        if (!checkEmail.test(email)) {
            inputErrors["email"] = "Please enter a valid email address";
        }
        /**
         * Check the password to make sure it is longer than 8 characters
         */
        if (password.length < 8) {
            inputErrors["password"] = "Please make sure your password is 8 characters or longer";
        }
        /**
         * Check to see if there were any errors added into the object
         */
        if (Object.keys(inputErrors).length === 0) {
            /**
             * Start the process of signing the user up
             */
            processSignup();
        } else {
            /**
             * Push the errors into the state
             */
            setErrors(inputErrors);
            setLoading(false);
        }
    }

    /**
     * Process the signup of the new user.
     * 
     * We'll create them an authentication account and add them and the agency document into 
     * firestore, where a cloud funciton will pick up the rest with setting the agency up in 
     * stripe, and sending out welcome emails etc.
     */
    const processSignup = async () => {
        /**
         * Create the user and auth account
         */
        const userID = await firebase.auth()
            .createUserWithEmailAndPassword(email, password)
            .then(async (newUser) => {
                /**
                 * Return the new users ID
                 */
                return newUser.user.uid;
            }).catch((err) => {
                /**
                 * If the email already exists
                 */
                if (err.code === "auth/email-already-in-use") {
                    /**
                     * Show an error in the state
                     */
                    setErrors({ ...errors, email: "Hang on, we know you. Did you mean to login?" });
                }
                /**
                 * Return false to stop the onboarding
                 */
                return false
            });
        /**
         * If there was a new user ID returned
         */
        if (userID) {
            /**
             * Setup the new agency document
             */
            const agencyID = await db.collection("agencies").add({
                name: businessName,
                created_by: userID,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                users: arrayUnion(userID),
            }).then((newAgency) => { return newAgency.id });
            /**
             * Create the users document in firestore
             */
            await db.doc(`users/${userID}`).set({
                name: fullName,
                email: email,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                linkedAccounts: {},
                notifications: defaultNotifications,
                agencies: arrayUnion(agencyID),
            }, { merge: true });
            /**
             * Add the agency to the users "agencies" subcollection
             */
            await db.doc(`users/${userID}/agencies/${agencyID}`).set({
                assigned: firebase.firestore.FieldValue.serverTimestamp(),
            });
            /**
             * Then add the user to the agencies
             */
            await db.doc(`agencies/${agencyID}/users/${userID}`).set({
                assigned: firebase.firestore.FieldValue.serverTimestamp(),
                status: "active",
                permissions: {
                    MANAGE_CLIENTS: true,
                    MANAGE_USERS: true,
                    ADMINISTRATE: true
                }
            });
            /**
             * Then redirect the user to the dashboard of the app
             */
            window.location.href = "/dashboard";
        } else {
            /**
             * Hide the loading spinner on the button 
             */
            setLoading(false);
        }
    }

    return (
        <div id="signup-wrapper">
            <div className="signup-logo">
                <img src={FeevalLogo} alt="Feeval Platform" />
            </div>

            <div className="signup-input-column">
                <h1>Welcome to Feeval!</h1>
                <p>You're 10 seconds away from streamlining your workflow...</p>

                <div className="signup-inputs">
                    <Input
                        type="text"
                        label="What's your name?"
                        showLabel={true}
                        placeholder="What's your name?"
                        value={fullName}
                        onChange={setFullName}
                        error={errors.fullName} />

                    <Input
                        type="text"
                        label="And your business name?"
                        showLabel={true}
                        placeholder="And your business name?"
                        value={businessName}
                        onChange={setBusinessName}
                        error={errors.businessName}
                        note="This is your agency/freelance title you operate under" />

                    <Input
                        type="text"
                        label="Your email address"
                        showLabel={true}
                        placeholder="Your email address"
                        value={email}
                        onChange={setEmail}
                        error={errors.email} />

                    <div className="password-input-wrapper">
                        <div
                            className="password-view-toggle"
                            onMouseDown={() => setShowingPassword(true)}
                            onMouseUp={() => setShowingPassword(false)}>
                            {!showingPassword && <EyeOpenIcon />}
                            {showingPassword && <EyeSlashedIcon />}
                        </div>

                        <Input
                            type={showingPassword ? "text" : "password"}
                            label="Choose a password"
                            showLabel={true}
                            placeholder="Choose a password"
                            value={password}
                            onChange={setPassword}
                            error={errors.password} />
                    </div>
                </div>

                <small>By creating an account you're agreeing to our <a href="https://www.feeval.com/docs/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://www.feeval.com/docs/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></small>

                <Button
                    label="Create my account"
                    loading={loading}
                    loadingText="Setting you up..."
                    onClick={() => checkUserInputs()} />

                <small>Already got an account? <NavLink to="/login">Login here</NavLink></small>
            </div>

            <div className="side-accents">
                <SideAccents />
            </div>
        </div>
    );
}

export default Signup;