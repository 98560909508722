import React, { useState, useContext, useEffect, useRef } from "react";
import { ContentContext } from "../../../../utils/providers/content";
import { AlertsContext } from "../../../../utils/providers/alerts";
import { db, auth, arrayUnion } from "../../../../utils/firebase";
import { getUserAgent } from "../../../../utils/helpers";
import firebase from "firebase";

/**
 * UI components
 */
import { VerticalDotsIcon } from "../../../../utils/svgs";
import Button from "../../../design-system/ui/button/button";
import TextArea from "../../../design-system/ui/inputs/textarea";
import Replies from "./replies/replies";

/**
 * Functional component for displaying the comment data on the screen
 */
function Comment(props) {
    const [toggled, setToggled] = useState(false);
    const [menuShowing, setMenuShowing] = useState(false);
    const [replyToggled, setReplyToggled] = useState(false);
    const [reply, setReply] = useState("");
    const [postingReply, setPostingReply] = useState(false);

    /**
     * Get the relevant IDs for this content from the content context
     */
    const { ids, setSeekToSeconds, currentComment, setCurrentComment } = useContext(ContentContext);

    /**
     * Deconstruct the comment data from the props
     */
    const { id, status, user, date, comment, useragent, secondsAt, taskID } = props.comment;

    /**
     * Deconstruct the pushAlert function from the alerts context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Setup a ref for the menu
     */
    const menuRef = useRef(null);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Function to check the target of the click event
         */
        const clickedOffMenu = (e) => {
            /**
             * If the click was outside the menu and wasn't on the toggle button
             */
            if (!menuRef.current.contains(e.target) && e.target.id !== `vertical-menu-toggle_${id}`) {
                /**
                 * Hide the menu
                 */
                setMenuShowing(false);
            }
        }
        /**
         * Setup the listener on the window for the click event
         */
        window.addEventListener("mousedown", clickedOffMenu);
        /**
         * Remove the document listener
         */
        return () => {
            window.removeEventListener("mousedown", clickedOffMenu);
        }
    }, []);

    /**
     * Toggle the reply form for the comment
     */
    const toggleCommentReply = (e) => {
        /**
         * Prevent the click bubbling up
         */
        e.stopPropagation();
        /**
         * Hide the toggle menu
         */
        setMenuShowing(false);
        /**
         * Toggle the reply form
         */
        setReplyToggled(true);
        /**
         * Show the lower half of the comment window
         */
        setToggled(true);
    }

    /**
     * Add the comment as a new task
     */
    const addCommentAsTask = async (e) => {
        /**
         * Stop the click action bubbling up
         */
        e.stopPropagation();
        /**
         * Get the IDs from the 'ids' property on the content context
         */
        const { client, project, content, file } = ids;
        /**
         * Create a new task document with the associated comment
         */
        const taskID = await db.collection("tasks").add({
            title: comment,
            description: "",
            client,
            project,
            content,
            file,
            followers: arrayUnion(auth.currentUser.uid),
            created_by: auth.currentUser.uid,
            assigned_to: auth.currentUser.uid,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            last_updated: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((taskDoc) => {
            /**
             * Return the new task document ID
             */
            return taskDoc.id;
        });
        /**
         * Then update the comment document with the taskID
         */
        await db.doc(`clients/${client}/projects/${project}/content/${content}/files/${file}/comments/${id}`)
            .update({ taskID });
        /**
         * Push an alert to say it was added
         */
        pushAlert({
            title: "Task added",
        });
    }

    /**
     * Mark the comment document as complete
     */
    const markCommentAsComplete = (e) => {
        /**
         * Stop the propagation of the click
         */
        e.stopPropagation();
        /**
         * Hide the toggle menu
         */
        setMenuShowing(false);
        /**
         * Get the IDs from the 'ids' property on the content context
         */
        const { client, project, content, file } = ids;
        /**
         * Update the comment record in the database with a complete status
         */
        db.doc(`clients/${client}/projects/${project}/content/${content}/files/${file}/comments/${id}`)
            .update({ status: "complete" });
    };

    /**
     * Remove the comment document from the file
     */
    const removeComment = (e) => {
        /**
         * Stop the propagation of the click
         */
        e.stopPropagation();
        /**
         * Get the IDs from the 'ids' property on the content context
         */
        const { client, project, content, file } = ids;
        /**
         * Update the comment record in the database with a complete status
         */
        db.doc(`clients/${client}/projects/${project}/content/${content}/files/${file}/comments/${id}`)
            .delete();
    };

    /**
     * Toggle the showing status of the comment element
     */
    const toggleComment = () => {
        /**
         * Flip the staus of the toggled status in the state
         */
        setToggled((toggled) => !toggled);
    };

    /**
     * Whent he current comment ID is updated from the context
     */
    useEffect(() => {
        /**
         * Set the local toggled attribute to the result of whether the current ID and 
         * current Comment ID match
         */
        setToggled(currentComment === id);
    }, [currentComment]);

    /**
     * When the toggled status of the comment changes
     */
    useEffect(() => {
        /**
         * Is the current comment now toggled?
         */
        if (toggled) {
            /**
             * Set the current comment ID into the context
             */
            setCurrentComment(id);
            /**
             * Is there a secondsAt attribute?
             */
            if (secondsAt) {
                /**
                 * Calculate the second we need to seek the video to
                 */
                const split = secondsAt.split(":");
                const minutes = parseInt(+split[0]);
                const seconds = parseInt(split[1]);
                const seekToSeconds = parseFloat(minutes * 60 + seconds);
                /**
                 * Push it to the context
                 */
                setSeekToSeconds(seekToSeconds);
            }
        } else {
            /**
             * If it was un-toggled and the current comment in context is this one
             */
            if (currentComment === id) {
                /**
                 * Empty the current comment
                 */
                setCurrentComment("");
            }
        }
    }, [toggled]);

    /**
     * Passing the state toggle through here first so we can cancel the propagation of the click
     */
    const toggleMenuShowing = (e) => {
        /**
         * Stop the 
         */
        e.stopPropagation();
        /**
         * Toggle the menu to show for the comment actions
         */
        setMenuShowing(!menuShowing);
    }

    /**
     * 
     */
    const postCommentReply = async () => {
        /**
         * 
         */
        setPostingReply(true);
        /**
         * Get the IDs from the 'ids' property on the content context
         */
        const { client, project, content, file } = ids;
        /**
         * 
         */
        await db.collection(
            `clients/${client}/projects/${project}/content/${content}/files/${file}/comments/${id}/replies`
        ).add({
            comment: reply,
            status: "active",
            user: auth.currentUser.uid,
            useragent: getUserAgent(),
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * 
         */
        setToggled(false);
        setReplyToggled(false);
        setPostingReply(false);
        setReply("");
        /**
         * 
         */
        pushAlert({
            title: "Reply posted to thread",
        });
    }

    return (
        <div className="comment-wrapper">
            <div
                id={id}
                className={[
                    "comment-element",
                    status,
                    (currentComment === id) && "showing",
                ].join(" ")} >

                <div className="ce-upper" onClick={() => toggleComment()}>
                    <div className="ce-name">
                        <p>
                            {user} <span>{date}</span>
                        </p>

                        <div
                            id={`vertical-menu-toggle_${id}`}
                            className="vertical-menu-toggle"
                            onClick={(e) => toggleMenuShowing(e)}>
                            <VerticalDotsIcon />
                        </div>

                        <div
                            ref={menuRef}
                            className={["vertical-menu", menuShowing && "is-showing"].join(" ")}>
                            <p onClick={(e) => toggleCommentReply(e)}>
                                Reply to thread
                            </p>

                            {/* If there is no task ID associated with the comment, show the add button */}
                            {!taskID &&
                                <p onClick={(e) => addCommentAsTask(e)}>
                                    Add to tasks
                                </p>
                            }

                            {(status !== "complete") &&
                                <p onClick={(e) => markCommentAsComplete(e)}>
                                    Mark as complete
                                </p>
                            }

                            <p className="delete-comment" onClick={(e) => removeComment(e)}>
                                Delete
                            </p>
                        </div>
                    </div>
                    <div className="ce-comment">
                        <p>
                            {secondsAt && <span>({secondsAt})</span>} {comment}
                        </p>
                    </div>
                </div>

                <div className="ce-lower">
                    {!replyToggled &&
                        <div className="ce-meta" onClick={() => toggleComment()}>
                            <p>
                                Comment left on a{" "}
                                <span>
                                    {useragent.os.name} {useragent.platform.type}
                                </span>{" "}
                                with{" "}
                                <span>
                                    {useragent.browser.name} {useragent.browser.version?.split(".")[0]}
                                </span>
                                . Screen dimensions were{" "}
                                <span>
                                    {useragent.window.viewportWidth}x{useragent.window.viewportHeight}
                                </span>
                                .
                            </p>
                        </div>
                    }

                    {replyToggled &&
                        <div className="comment-reply-wrapper">
                            <TextArea
                                value={reply}
                                onChange={setReply}
                                placeholder="Type out your reply..." />
                            <Button
                                label="Post reply"
                                disabled={!reply}
                                loading={postingReply}
                                loadingText="Posting reply..."
                                onClick={() => postCommentReply()} />
                        </div>
                    }
                </div>
            </div>

            <Replies ids={ids} comment={id} status={status} />
        </div>
    );
}

export default Comment;
