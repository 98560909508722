import React, { useState, useRef, useContext, useEffect } from "react";
import { ContentContext } from "../../../utils/providers/content";
import { db } from "../../../utils/firebase";
import ReactPlayer from "react-player";

/**
 * Styles
 */
import "./content-types.scss";

/**
 * UI Components
 */
import Timeline from "./video/timeline";
import Feedback from "../../../components/content/feedback-tile/feedback";
import Drawing from "../../../components/content/drawings/drawing";
import CommentsOverlay from "../../../components/content/comments-overlay/comments-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward, faStepBackward, faFastForward, faStepForward, faPause, faPlay } from "@fortawesome/free-solid-svg-icons";

/**
 * Container for outputting video formats in the review panels
 */
const VideoContainer = (props) => {
    const [media, setMediaMeta] = useState({
        playing: false,
        duration: 0,
        durationSeconds: 0,
        progress: 0,
        secondsAt: 0,
    });
    const [manifestURL, setManifestURL] = useState("");
    const [maxHeight, setMaxHeight] = useState(0);
    const [maxWidth, setMaxWidth] = useState(0);
    const [actualHeight, setActualHeight] = useState(0);
    const [actualWidth, setActualWidth] = useState(0);
    const [containerSize, setContainerSize] = useState({
        height: 100,
        width: 100
    });

    /**
     * Reference handler for getting data back from the video player
     */
    const player = useRef(null);

    /**
     * Reference handler for accessing the container div of the video
     */
    const containerRef = useRef(null);

    /**
     * Ref for the content holder
     */
    const contentContainerRef = useRef(null);

    /**
     * Get the required data from the content context
     */
    const { ids, content, feedbackTool, setMedia, seekToSeconds } = useContext(ContentContext);

    /**
     * On the update of the content from context
     */
    useEffect(() => {
        /**
         * Fetch the file data from the database
         */
        fetchFileManifest();
    }, [content]);

    /**
     * On the update of the media object
     */
    useEffect(() => {
        /**
         * Push the update into the content context
         */
        setMedia(media);
    }, [setMedia, media]);

    /**
     * On window re-size we need to adjust the max height and widths the videos can display in
     */
    useEffect(() => {
        /**
         * Call the function to get some inital values
         */
        handleWindowResize();
        /**
         * Then add the event listener to the window to update state when it changes
         */
        window.addEventListener("resize", handleWindowResize);
        /**
         * Remove the event listener on the component unmount
         */
        return () => window.removeEventListener("resize", handleWindowResize);
    });

    /**
     * When the 
     */
    useEffect(() => {
        setTimeout(() => {
            /**
             * Get the size of the content container
             */
            const containerHeight = contentContainerRef.current?.getBoundingClientRect().height;
            const containerWidth = contentContainerRef.current?.getBoundingClientRect().width;
            /**
             * Update the state with those values
             */
            setContainerSize({
                height: containerHeight,
                width: containerWidth,
            });
        }, 500);
    }, [maxHeight, maxWidth]);

    /**
     * When the maxHeights or maxWidths are recalculated
     */
    useEffect(() => {
        calculateDimensions();
    }, [maxHeight, maxWidth]);

    /**
     * Ran on a window resize event, we calcuate here the max heights and width the video can display as
     *
     * @type {const}
     */
    const handleWindowResize = () => {
        /**
         * Current padding value for the wrapping div
         */
        const padding = 25;
        /**
         * Get the height and width of the containing div
         */
        const containerHeight = containerRef.current.clientHeight;
        const containerWidth = containerRef.current.clientWidth;
        /**
         * Calculate max heights and widths
         */
        const maxHeight = containerHeight - padding * 2 - 120;
        const maxWidth = containerWidth - padding * 2;
        /**
         * Set the new max values to state
         */
        setMaxHeight(maxHeight);
        setMaxWidth(maxWidth);
    };

    /**
     *
     *
     * @type {const}
     */
    const calculateDimensions = () => {
        /**
         * Has the player element loaded in
         */
        if (player?.current) {
            /**
             * Get the video tag element
             */
            const playerElement = player.current.getInternalPlayer();
            if (playerElement) {
                /**
                 * First let's try fit it in via width
                 */
                setActualWidth(maxWidth);
                setActualHeight("auto");
                /**
                 * Now get the height and width of the player
                 */
                const playerHeight = playerElement.clientHeight;
                const playerWidth = playerElement.clientWidth;
                /**
                 * Work out an aspect ratio for the video
                 */
                const aspectRatio = playerWidth / playerHeight;
                /**
                 * Is the height overflowing?
                 */
                if (playerHeight > maxHeight) {
                    setActualHeight("auto");
                    setActualWidth(parseInt(maxHeight * aspectRatio));
                }
                /**
                 * Is the width overflowing?
                 */
                if (playerWidth > maxWidth) {
                    setActualHeight("auto");
                    setActualWidth(maxWidth);
                }
            }
        }
    };

    /**
     * Fetches the URL of the video's manifest from the database to store in state and load the video player
     *
     * @type {const}
     * @returns Sets the file's manifest URL to state
     */
    const fetchFileManifest = () => {
        /**
         * Deconstruct the IDs needed from the ids object
         */
        const { client, project, content, file } = ids;
        /**
         * Fetch the file data from the database
         */
        db.doc(`clients/${client}/projects/${project}/content/${content}/files/${file}`).get().then((fileDoc) => {
            /**
             * If the file document exists
             */
            if (fileDoc.exists) {
                /**
                 * Decosntruct the transcode object from the file document
                 */
                const { transcode } = fileDoc.data();
                /**
                 * Update the state with the manifest URL
                 */
                setManifestURL(transcode?.manifest);
                /**
                 * Then we want to update the state in the component above to hide the loading
                 */
                props.onLoad();
            }
        });
    };

    /**
     * Deconstruct the media data from the player
     */
    const { playing, duration, durationSeconds, secondsAt, progress } = media;

    /**
     * Toggle the playing value in state
     */
    const handlePlayPause = () => {
        setMediaMeta({
            ...media,
            playing: !media.playing,
        });
    };

    /**
     * Function to pass down to the feddback tile to pause the playback on tile click
     */
    const pausePlayback = () => {
        setMediaMeta({
            ...media,
            playing: false,
        });
    };

    /**
     * Update the state of the current component with the duration of the media in seconds
     *
     * @param {float} duration Duration of the media in ms from the player
     */
    const setDuration = (duration) => {
        setMediaMeta({
            ...media,
            durationSeconds: duration,
            duration: new Date(duration * 1000).toISOString().substr(14, 5),
        });
    };

    /**
     * Updates the state of this component with the current played time to display in a seconds format
     * along the timeplayer player underneath the video
     *
     * @param {object} data Object back from the player containing parameters needed such as playedSeconds etc.
     */
    const displayProgress = (data) => {
        setMediaMeta({
            ...media,
            progress: data.playedSeconds,
            secondsAt: new Date(data.playedSeconds * 1000).toISOString().substr(14, 5),
        });
    };

    /**
     * Handle a seek change on the video player (user scrolls the bar along the bottom)
     *
     * @param {float} value Float value for the timestamp the player needs to seek to
     */
    const handleSeekChange = (value) => {
        player.current.seekTo(parseFloat(value));
    };

    /**
     * On update of the seek seconds from context.
     *
     * This is a value passed up from a comment click event.
     */
    useEffect(() => {
        /**
         * Ensure we have a connection to the player ref and then pass the seconds value to the
         * seek handler to change the video player
         */
        player?.current && handleSeekChange(seekToSeconds);
    }, [seekToSeconds]);

    return (
        <div className="view-component video" ref={containerRef}>
            <div className="content-media-holder" id="content-media-holder" ref={contentContainerRef}>
                {/* Video component */}
                {manifestURL && (
                    <ReactPlayer
                        ref={player}
                        url={manifestURL}
                        playing={playing}
                        onDuration={setDuration}
                        onProgress={displayProgress}
                        className="video-player-wrapper"
                        style={{ maxHeight: `${maxHeight}px`, maxWidth: `${maxWidth}px` }}
                        width={actualWidth || "100%"}
                        height={actualHeight || "100%"}
                    />
                )}

                {/* Feedback tile */}
                <Feedback pause={pausePlayback} />

                {/* Drawing tile */}
                <div className={["content-feedback-tile pen", (feedbackTool === "PEN") && "is-active"].join(" ")}>
                    <Drawing containerSize={containerSize} />
                </div>

                {/* Comments overlay */}
                <CommentsOverlay containerSize={containerSize} />
            </div>

            {/* Video Playback Controls */}
            <div className="media-controls">
                <div className="media-timeline">
                    {/* Current progress through the video in seconds */}
                    <div className="timeline-timestamp">{secondsAt}</div>
                    {/* Video timeline wrapper */}
                    <div className="timeline-wrapper">
                        {/* Comment tabs holder to display above the player */}
                        <div className="progress-comments"></div>
                        {/* Progress bar showing progress through the video */}
                        <Timeline value={progress} maximum={durationSeconds} onSeekEnd={handleSeekChange} />
                    </div>
                    {/* Duration of the video in seconds */}
                    <div className="timeline-timestamp">{duration}</div>
                </div>
                {/* Buttons for the video playback bar */}
                <div className="media-buttons">
                    <div>
                        <FontAwesomeIcon icon={faFastBackward} />
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faStepBackward} />
                    </div>
                    <div onClick={handlePlayPause}>
                        {playing && <FontAwesomeIcon icon={faPause} />}
                        {!playing && <FontAwesomeIcon icon={faPlay} />}
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faStepForward} />
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faFastForward} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoContainer;
