import React, { useEffect, useState, useContext } from "react";
import { ContentContext } from "../../../utils/providers/content";
import { db, auth, getTimestamp } from "../../../utils/firebase";
import { getUserAgent } from "../../../utils/helpers";
import "./comment-window.scss";

/**
 * UI Components
 */
import TextArea from "../../../components/design-system/ui/inputs/textarea";
import Button from "../../../components/design-system/ui/button/button";

/**
 * Functional component that shows the inputs needed for the user to leave a comment when they
 * have clicked on the feedback tile.
 *
 * The functionality to update the database with the new comment also sits in this functional component
 * to keep things in the same please, this will probably need changing when the drawing aspect comes into play.
 *
 * @returns HTML markup and functionality for the comment modal that appears when a user clicks
 * on the feedback tile
 */
function CommentWindow(props) {
    const [displayWindow, setDisplayWindow] = useState(false);
    const [comment, setComment] = useState("");
    const [addingComment, setAddingComment] = useState(false);
    const [commentWindowStyles, setCommentWindowstyles] = useState({});

    /**
     * Get the cursor positions and media data from the content context
     */
    const { ids, content, cursorX, cursorY, media } = useContext(ContentContext);

    /**
     * Component update on the props show, xPos and yPos being updated
     */
    useEffect(() => {
        /**
         * Toggle the display as required
         */
        setDisplayWindow(props.show);
        /**
         * Update the styling of the window to appear where required
         */
        setCommentWindowstyles({ top: `${cursorY}%`, left: `${cursorX}%` });
    }, [props.show, cursorX, cursorY, displayWindow]);

    /**
     * Saves the comment to the database
     *
     * @type {const}
     */
    const saveComment = async () => {
        /**
         * Toggle the leave feedback button
         */
        setAddingComment(true);
        /**
         * Deconstruct the IDs needed from the content context
         */
        const { client, project, content, file } = ids;

        /**
         * Get the current timestamp
         */
        const timestamp = await getTimestamp();
        /**
         * Add the comment to the content sub-collection in the database
         */
        await db.collection(`clients/${client}/projects/${project}/content/${content}/files/${file}/comments`).add({
            user: auth.currentUser.uid,
            date: timestamp,
            status: "active",
            task: null,
            comment: comment,
            x_pos: cursorX,
            y_pos: cursorY,
            useragent: getUserAgent(),
            secondsAt: media.secondsAt || null,
        });
        /**
         * Reset the comment field and enable the button again
         */
        setComment("");
        setAddingComment(false);
        props.cancel();
    };

    return (
        <div className={["feedback-comment-window", displayWindow && "showing"].join(" ")} style={commentWindowStyles}>

            <TextArea
                value={comment}
                placeholder="Add comment..."
                onChange={setComment} />

            <Button
                label="Leave Feedback"
                loading={addingComment}
                loadingText="Saving feedback..."
                onClick={() => saveComment()}
                disabled={!comment} />

            <Button
                label="Cancel"
                type="NEGATIVE"
                onClick={() => props.cancel()} />

            {/* Show the seconds paused at if the content type is a video */}
            {content.type.format === "video" &&
                <p className="feedback-comment-seconds">Comment pinned at: {media.secondsAt}</p>
            }
        </div>
    );
}

export default CommentWindow;
