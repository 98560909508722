import React, { useContext } from "react";
import { AuthContext } from "./providers/auth";
import { Switch, Route, useLocation } from 'react-router-dom';

/**
 * App context providers
 */
import AppContexts from "./contexts";

/**
 * UI containers
 */
import Navigation from "../components/app/navigation/navigation";
import GenericRoutes from "../components/app/router/generic-routes";
import SpecificRoutes from "../components/app/router/specific-routes";

/**
 * Login page route
 */
import Login from "../containers/login/login";
import Signup from "../containers/signup/signup";

/**
 * Functional component to return the routing for the app. Checks are done at this level for calls to 
 * generic pages like signup/login or specifc calls wrapped in a user context for app-level pages 
 * like clients or content.
 */
function AppRoutes() {
    /**
     * Get the user object from the Auth context
     */
    const { user } = useContext(AuthContext);

    /**
     * Get the pathname of the current page
     */
    const { pathname } = useLocation();

    /**
     * Get the base for the pathname
     */
    const pathBase = pathname.split("/")[1];

    /**
     * Define a list of url paths that do not require a navigation to be shown
     */
    const genericPages = ["login", "signup", "content", "password-reset"];

    /**
     * Are we on a generic page? So the user auth is not of interest
     */
    if (genericPages.includes(pathBase)) {
        /**
         * Return the generic routes
         */
        return (
            <AppContexts>
                {/* Main app DOM element */}
                <div id="app-splash">
                    <GenericRoutes />
                </div>
            </AppContexts>
        );
    } else {
        /**
         * Is the auth context in a loading state
         */
        if (user.loading) {
            /**
             * Show the loading tile
             */
            return "loading";
        } else {
            /**
             * When we are done loading, is there an authenticed user?
             */
            if (user.logged_in) {
                return (
                    <AppContexts>
                        <section id="app-screen-wrapper">
                            {/* Does this page require a navigation to be shown */}
                            {!genericPages.includes(pathname) && <Navigation />}

                            {/* Main app DOM element */}
                            <div id="app-screen-height-contain">
                                <div id="app-screen-inner">
                                    <SpecificRoutes />
                                </div>
                            </div>
                        </section>
                    </AppContexts>
                );
            } else {
                /**
                 * If we are on an app pathname, but no user is logged in
                 */
                return (
                    <AppContexts>
                        <div id="app-splash">
                            <Switch>
                                {/* Expose a path for the signup page */}
                                <Route path="/signup" exact component={Signup} />
                                {/* Route all other paths to the login page */}
                                <Route path="*" component={Login} />
                            </Switch>
                        </div>
                    </AppContexts>
                );
            }
        }
    }
}

export default AppRoutes;