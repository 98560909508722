import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns, faUserFriends, faTasks, faChartArea, faProjectDiagram, faCaretDown, faCog, faInfoCircle, faHeadset } from '@fortawesome/free-solid-svg-icons';

import AgencyLink from "./agency-link/agency-link";

/**
 * Functional component to return the HTML markup for the agency user navigation
 */
function AgencyNav(props) {
    /**
     * Deconstruct the agencies for the user from the props (no need to pull the context in again)
     */
    const { agencies } = props;

    /**
     * Get the pathname for the current page from the react router
     */
    const { pathname } = useLocation();

    return (
        <>
            {/* Dashboard */}
            <div className="nav-block">
                <ul>
                    <li>
                        <NavLink to="/dashboard" activeClassName="active">
                            <div className="nav-item-icon">
                                <FontAwesomeIcon icon={faColumns} />
                            </div>
                            Dashboard
                        </NavLink>
                    </li>
                </ul>
            </div>

            {/* Manage tabs */}
            <div className="nav-block">
                <h6>Manage</h6>
                <ul>
                    <li>
                        <NavLink to="/clients" activeClassName="active">
                            <div className="nav-item-icon">
                                <FontAwesomeIcon icon={faUserFriends} />
                            </div>
                            Clients
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/tasks" activeClassName="active">
                            <div className="nav-item-icon">
                                <FontAwesomeIcon icon={faTasks} />
                            </div>
                            Tasks
                        </NavLink>
                    </li>
                </ul>
            </div>

            {/* Account tabs */}
            <div className="nav-block">
                <h6>Account</h6>
                <ul>
                    <li className="inactive">
                        <NavLink to="/reports" activeClassName="active">
                            <div className="nav-item-icon">
                                <FontAwesomeIcon icon={faChartArea} />
                            </div>
                            Reports
                        </NavLink>
                    </li>

                    {/* Are we displaying just a single agency */}
                    {agencies.length === 1 &&
                        <li>
                            <NavLink
                                to={`/agency/${agencies[0]}/users`}
                                activeClassName="active"
                                isActive={(match, location) => {
                                    if (location.pathname.split("/")[1] === "agency") {
                                        return true;
                                    }
                                }}>
                                <div className="nav-item-icon">
                                    <FontAwesomeIcon icon={faProjectDiagram} />
                                </div>
                                Agency
                            </NavLink>
                        </li>
                    }

                    {/* do we need to display multiple agencies? */}
                    {agencies.length > 1 &&
                        <div className="nav-agencies">
                            <div className="nav-agencies-title">
                                <div className="nav-item-icon">
                                    <FontAwesomeIcon icon={faProjectDiagram} />
                                </div>
                                <p>Agencies</p>
                                <div className="down-caret">
                                    <FontAwesomeIcon icon={faCaretDown} />
                                </div>
                            </div>
                            <div className="nav-agencies-list">
                                {agencies.map((agency) => (
                                    <AgencyLink key={agency} agencyID={agency} />
                                ))}
                            </div>
                        </div>
                    }

                    <li>
                        <NavLink
                            to="/settings/edit"
                            activeClassName="active"
                            isActive={() => [
                                "/settings/edit",
                                "/settings/security",
                                "/settings/notifications",
                            ].includes(pathname)}>
                            <div className="nav-item-icon">
                                <FontAwesomeIcon icon={faCog} />
                            </div>
                            Settings
                        </NavLink>
                    </li>
                </ul>
            </div>

            {/* Help & support */}
            <div className="nav-block">
                <h6>Help</h6>
                <ul>
                    <li>
                        <a href="https://www.feeval.com/support/" target="_blank" rel="noopener noreferrer">
                            <div className="nav-item-icon">
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </div>
                            Support Docs
                        </a>
                    </li>
                    <li>
                        <a href="https://www.feeval.com/help/" target="_blank" rel="noopener noreferrer">
                            <div className="nav-item-icon">
                                <FontAwesomeIcon icon={faHeadset} />
                            </div>
                            Get Help
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default AgencyNav;